import { ClientInvestmentState, ClientInvestmentTypes, CREATE_INVESTMENT, SET_POTENTIAL_INVESTMENT } from "./types"
import { ClientInvestment, SimulationTick, ClientInvestmentReturn, Security } from "../../domain"
import { TICK_SIMULATION, RESET_SIMULATION } from "../simulation"
import { getMiningReturns, getValueAt, MiningReturnsParameters, getSecuritizedSale } from "../../util"

const initialState: ClientInvestmentState = {
  investments: [],

}

export const getInitialFiat = (securitites: Array<Security>) => {
  return securitites.reduce((prev: number, curr: Security) => (prev + curr.cost), 0)
}

export const calculateNextReturns = (investment: ClientInvestment, simulationTick: SimulationTick): ClientInvestmentReturn => {
  if (simulationTick.newTimestamp < investment.tranche.parameters.startDate) {
    return {
      timestamp: simulationTick.newTimestamp - simulationTick.elapsed,
      cryptoHolding: 0,
      cryptoMined: 0,
      fiatHolding: -getInitialFiat(investment.securities),
      powerCost: 0
    }
  }
  const lastVal: ClientInvestmentReturn = (
    investment.actualReturns.length > 0
      ? investment.actualReturns[investment.actualReturns.length - 1]
      : {
        timestamp: simulationTick.newTimestamp - simulationTick.elapsed,
        cryptoHolding: 0,
        cryptoMined: 0,
        fiatHolding: -getInitialFiat(investment.securities),
        powerCost: 0
      }
  )
  const { mine, startDate, term } = investment.tranche.parameters;
  if (simulationTick.oldTimestamp > startDate + term.approxMs) {
    return lastVal;
  }

  const ts = simulationTick.newTimestamp - simulationTick.elapsed / 2
  const electricityCost = getValueAt(ts, simulationTick.parameters.pwrPriceSeries);
  const params: MiningReturnsParameters = {
    from: simulationTick.newTimestamp - simulationTick.elapsed,
    to: simulationTick.newTimestamp,
    hashingPowerInTH: mine.hashingPower * investment.calculation.ownershipPercent,
    pwrConsumptionInW: mine.powerConsumption * investment.calculation.ownershipPercent,
    pwrCostPerKWH: electricityCost,
    miningDifficultyInT: getValueAt(ts, simulationTick.parameters.miningDifficultySeries),
    blockReward: getValueAt(ts, simulationTick.parameters.blockYieldSeries)
  }
  const miningReturns = getMiningReturns(params);
  const fiatRate = getValueAt(ts, simulationTick.parameters.btcPriceSeries);
  const fiatValue = getSecuritizedSale(miningReturns.cryptoMined, fiatRate, investment.securities);

  // TODO progressively sell the crypto
  const actualReturn: ClientInvestmentReturn = {
    timestamp: simulationTick.newTimestamp,
    cryptoMined: lastVal.cryptoMined += miningReturns.cryptoMined,
    cryptoHolding: lastVal.cryptoHolding,
    fiatHolding: lastVal.fiatHolding += fiatValue,
    powerCost: lastVal.powerCost += miningReturns.electricityCost * (1 + mine.feePercent)
  }

  return actualReturn;
}

export function clientInvestmentReducer(
  state = initialState,
  action: ClientInvestmentTypes
): ClientInvestmentState {
  const { potentialInvestment } = state;
  switch (action.type) {
    case CREATE_INVESTMENT:
      return {
        ...state,
        investments: [
          ...state.investments,
          action.payload
        ]
      }
    case SET_POTENTIAL_INVESTMENT:
      return {
        ...state,
        potentialInvestment: action.payload
      }
    case TICK_SIMULATION:
      // TODO: Tick simulation

      if (potentialInvestment !== undefined) {
        const { startDate, term } = potentialInvestment.tranche.parameters;
        const { newTimestamp } = action.payload
        if (newTimestamp > startDate && newTimestamp < (startDate + term.approxMs)) {
          return {
            ...state,
            potentialInvestment: {
              ...potentialInvestment,
              actualReturns: [...potentialInvestment.actualReturns, calculateNextReturns(potentialInvestment, action.payload)]
            }
          }
        }
      }
      return state;

    case RESET_SIMULATION:
      if (potentialInvestment !== undefined) {
        return {
          ...state,
          potentialInvestment: {
            ...potentialInvestment,
            actualReturns: []
          }
        }
      }
      else {
        return state;
      }
    default:
      return state;
  }
}
