import React from 'react';
import { DraggableChart } from './draggable-chart';
import { DataPoint, SimulationParameters } from '../../domain';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { setSimulationParameters } from '../../store/simulation';

interface SimulationParametersViewProps {
  simulationTime: number;
  parameters: SimulationParameters;
  onUpdateSimulationParameters: (parameters: SimulationParameters) => void;
}

interface SimulationParametersViewState {
  btcPriceSeries: Array<DataPoint>;
  pwrPriceSeries: Array<DataPoint>;
  miningDifficultySeries: Array<DataPoint>;
  blockYieldSeries: Array<DataPoint>;
}

export class SimulationParametersView extends React.Component<SimulationParametersViewProps, SimulationParametersViewState> {
  constructor(props: SimulationParametersViewProps) {
    super(props);


    this.state = {
      btcPriceSeries: props.parameters.btcPriceSeries,
      pwrPriceSeries: props.parameters.pwrPriceSeries,
      miningDifficultySeries: props.parameters.miningDifficultySeries,
      blockYieldSeries: props.parameters.blockYieldSeries
    }
  }

  handleBtcPriceChange = (data: Array<DataPoint>) => {
    this.setState({ btcPriceSeries: data });
  }
  handlePwrPriceChange = (data: Array<DataPoint>) => {
    this.setState({ pwrPriceSeries: data });
  }
  handleMiningDificultyChange = (data: Array<DataPoint>) => {
    this.setState({ miningDifficultySeries: data });
  }
  handleBlockYieldChange = (data: Array<DataPoint>) => {
    this.setState({ blockYieldSeries: data });
  }
  handleSaveClick = () => {
    this.props.onUpdateSimulationParameters({
      ...this.props.parameters,
      btcPriceSeries: this.state.btcPriceSeries,
      pwrPriceSeries: this.state.pwrPriceSeries,
      miningDifficultySeries: this.state.miningDifficultySeries,
      blockYieldSeries: this.state.blockYieldSeries,
    });
  }

  render() {
    const {
      btcPriceSeries,
      pwrPriceSeries,
      miningDifficultySeries,
      blockYieldSeries,
    } = this.state;
    return (
      <div className='client-view clearfix'>
        <div className='row'>
          <div className='col-md-12'>
            <h3>Simulation Parameters</h3>
          </div>
          <div className='col-md-6'>
            <h5>BTC Price (USD)</h5>
            <DraggableChart
              data={btcPriceSeries}
              onChange={this.handleBtcPriceChange}
              simulationTime={this.props.simulationTime}
            />
          </div>
          <div className='col-md-6'>
            <h5>Power Price (USD/KWh)</h5>
            <DraggableChart
              data={pwrPriceSeries}
              onChange={this.handlePwrPriceChange}
              simulationTime={this.props.simulationTime}
            />
          </div>
          <div className='col-md-6'>
            <h5>Mining Difficulty</h5>
            <DraggableChart
              data={miningDifficultySeries}
              onChange={this.handleMiningDificultyChange}
              simulationTime={this.props.simulationTime}
            />
          </div>
          <div className='col-md-6'>
            <h5>Block Yield</h5>
            <DraggableChart
              data={blockYieldSeries}
              onChange={this.handleBlockYieldChange}
              simulationTime={this.props.simulationTime}
            />
          </div>
          <div className='col-md-12'>
            <button type='button' className='btn btn-primary' onClick={this.handleSaveClick}>
              Save
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    simulationTime: state.simulation.simulationTime,
    parameters: state.simulation.parameters,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onUpdateSimulationParameters: (parameters: SimulationParameters) => (
      dispatch(setSimulationParameters(parameters))
    ),
  }
}

export const SimulationParametersViewWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimulationParametersView)
