import { InvestmentTrancheClaim, InvestmentTrancheState, InvestmentTranche, Mine } from "../../domain"

export const RECEIVE_MINES = 'RECEIVE_MINES';
export const RECEIVE_TRANCHES = 'RECEIVE_TRANCHES';
export const CLAIM_TRANCHE = 'CLAIM_TRANCHE';

export interface ReceiveMines {
  type: typeof RECEIVE_MINES,
  payload: Array<Mine>;
}

export interface ReceiveTranches {
  type: typeof RECEIVE_TRANCHES,
  payload: Array<InvestmentTranche>;
}

export interface ClaimTranche {
  type: typeof CLAIM_TRANCHE;
  payload: InvestmentTrancheClaim;
}

export type MineActionTypes =
  ReceiveTranches |
  ClaimTranche |
  ReceiveMines

export interface MineState {
  availableTranches: Array<InvestmentTrancheState>;
  clientClaims: Array<InvestmentTrancheClaim>;
  mines: Array<Mine>;
}