import React from 'react';
import { AppState } from '../../store';
import { SimulationParameters } from '../../domain';
import { connect } from 'react-redux';
import { setSimulationParameters, beginSimulation, pauseSimulation, resetSimulation, SIMULATION_MODE } from '../../store/simulation';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

interface SimulationBarProps {
  simulationTime: number;
  mode: string;
  parameters: SimulationParameters;
  onUpdateSimulationParameters: (parameters: SimulationParameters) => void;
  onBegin: () => void;
  onPause: () => void;
  onReset: () => void;
}

export class SimulationBar extends React.Component<SimulationBarProps> {
  handleBeginClick = () => {
    this.props.onBegin();
  }
  handlePauseCick = () => {
    this.props.onPause();
  }
  handleResetClick = () => {
    this.props.onReset();
  }
  renderResetBtn() {
    switch (this.props.mode) {
      case SIMULATION_MODE.RUNNING:
      case SIMULATION_MODE.PAUSED:
      case SIMULATION_MODE.COMPLETE:
        return (
          <button className="btn btn-secondary my-2 my-sm-0" type="submit" onClick={this.handleResetClick}>Reset</button>
        )
      default:
        return (
          <button className="btn btn-secondary my-2 my-sm-0" type="submit" disabled={true}>Reset</button>
        )
    }
  }
  renderBeginPauseBtn() {
    switch (this.props.mode) {
      case SIMULATION_MODE.PENDING:
      case SIMULATION_MODE.PAUSED:
        return (
          <button className="btn btn-secondary my-2 my-sm-0" type="submit" onClick={this.handleBeginClick}>Begin</button>
        )
      case SIMULATION_MODE.RUNNING:
        return (
          <button className="btn btn-secondary my-2 my-sm-0" type="submit" onClick={this.handlePauseCick}>Pause</button>
        )
      case SIMULATION_MODE.COMPLETE:
        return (
          <button className="btn btn-secondary my-2 my-sm-0" type="submit" disabled={true}>Begin</button>
        )
      default:
        throw new Error("Unresognised mode: " + this.props.mode);
    }
  }
  handleSpeedChange = (value: number) => {
    this.props.onUpdateSimulationParameters({
      ...this.props.parameters,
      msPerMs: value
    })
  }
  render() {
    const { msPerMs } = this.props.parameters;
    const date = (new Date(this.props.simulationTime));
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (

      <div className="navbar navbar-expand-lg fixed-bottom navbar-dark bg-primary" >
        <div className="container">
          <span className='navbar-brand'>Simulation: </span>
          <div style={{ width: 200 }}>
            <label>Simulation Date</label><br />
            <span style={{ color: '#FFF' }}>{day < 10 ? '0' : ''}{day}/{month < 10 ? '0' : ''}{month}/{year}</span>
          </div>
          <div style={{ width: 200 }}>
            <label>Simulation Speed</label>
            <Slider min={1e5} max={5e7} value={msPerMs} onChange={this.handleSpeedChange} />
          </div>
          {this.renderResetBtn()}
          {this.renderBeginPauseBtn()}
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state: AppState) => {
  return {
    parameters: state.simulation.parameters,
    simulationTime: state.simulation.simulationTime,
    mode: state.simulation.mode,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onUpdateSimulationParameters: (parameters: SimulationParameters) => (
      dispatch(setSimulationParameters(parameters))
    ),
    onBegin: () => (dispatch(beginSimulation())),
    onPause: () => (dispatch(pauseSimulation())),
    onReset: () => (dispatch(resetSimulation()))
  }
}

export const SimulationBarWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimulationBar)
