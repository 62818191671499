import React from 'react';
import { formatThousands } from '../../util';

interface InputMoneyProps {
  label?: string;
  debounce: number;
  value: number;
  onChange: (value: number) => void;
}

interface InputMoneyState {
  tempVal: number;
}

export class InputMoney extends React.Component<InputMoneyProps, InputMoneyState> {
  static defaultProps = {
    debounce: 200
  }
  timer?: any;
  constructor(props: InputMoneyProps) {
    super(props);

    this.state = {
      tempVal: props.value
    }
  }
  propagate = () => {
    this.props.onChange(this.state.tempVal);
  }
  setPropagateTimer() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.propagate, this.props.debounce);
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      tempVal: parseInt(event.target.value.replace(/\D/g, ''))
    });
    this.setPropagateTimer();
  }
  renderLabel() {
    const { label } = this.props;
    if (label === undefined) {
      return null;
    }
    return (<label>{label}</label>)
  }
  render() {
    return (
      <div className='form-group'>
        {this.renderLabel()}
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">USD $</span>
          </div>
          <input className='form-control' type='text' value={formatThousands(this.state.tempVal)} onChange={this.handleChange} />
          <div className="input-group-append">
            <span className="input-group-text">.00</span>
          </div>
        </div>
      </div>
    )
  }
}