import { Uncertainty } from "../domain";
import { BTCUSD } from ".";
import { ONE_MONTH } from "../const";

const getVar = (init: number, clips: number) => (Math.pow((1 + init), clips) - 1 + init) / 2;

const __getBtcUsdUncertainty = (range: number): Uncertainty => {
  const months = range / ONE_MONTH;
  const varPerMonth = 0.07;
  const variance = getVar(varPerMonth, months);
  const final = BTCUSD * 1.1
  return {
    asset: 'BTC',
    range: range,
    start: Date.now(),
    currency: 'USD',
    valueInitial: BTCUSD,
    valueFinal: final,
    valueFinalStdDev: final * variance,
    variance: variance
  }
}

const BtcUncertaintyCache: { [index: number]: Uncertainty } = {}

export const getBtcUsdUncertainty = (range: number): Uncertainty => {
  if (BtcUncertaintyCache[range] === undefined) {
    BtcUncertaintyCache[range] = __getBtcUsdUncertainty(range);
  }
  return BtcUncertaintyCache[range];
}

export const getElectrictyUsdUncertainty = (range: number): Uncertainty => {
  const current = 0.04;
  const months = range / ONE_MONTH;
  const varPerMonth = 0.01;
  const variance = getVar(varPerMonth, months)
  return {
    asset: 'Electricty',
    range: range,
    start: Date.now(),
    currency: 'USD',
    valueInitial: current,
    valueFinal: current,
    valueFinalStdDev: current * variance,
    variance: variance
  }
}