import { ONE_DAY, ONE_MONTH, SIX_MONTH, ONE_YEAR } from "./time";
import { Term } from "../domain";

export const TERM = {
  ONE_WEEK: {
    label: 'One Week',
    approxMs: 7 * ONE_DAY,
    code: 'ONE_WEEK'
  } as Term,
  ONE_MONTH: {
    label: 'One Month',
    approxMs: ONE_MONTH,
    code: 'ONE_MONTH'
  } as Term,
  THREE_MONTH: {
    label: 'Three Months',
    approxMs: 3 * ONE_MONTH,
    code: 'THREE_MONTH'
  } as Term,
  SIX_MONTH: {
    label: 'Six Months',
    approxMs: SIX_MONTH,
    code: 'SIX_MONTH'
  } as Term,
  ONE_YEAR: {
    label: 'One Year',
    approxMs: ONE_YEAR,
    code: 'ONE_YEAR'
  } as Term
}

export const TERMS: Array<Term> = [
  TERM.SIX_MONTH,
  TERM.ONE_YEAR
]