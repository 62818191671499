import React from 'react';
import { TrancheListWrapped } from './tranche-list';

export class AdvancedInvest extends React.Component {
  render() {
    return (
      <TrancheListWrapped />

    )
  }
}