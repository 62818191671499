import React from 'react';
import { SimpleInvestViewWrapped } from './simple-invest';
import { AdvancedInvest } from './advanced-invest';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { CLIENT_INVEST, CLIENT_INVEST_SIMPLE, CLIENT_INVEST_ADVANCED, CLIENT_INVEST_TRANCHE } from '../../../const/routes';
import { TrancheViewWrapped } from './tranche-view';


export class InvestView extends React.Component {
  render() {
    return (
      <div className='client-view clearfix'>
        <div className='row'>
          <div className='col-md-12'>
            <Switch>
              <Route exact path={CLIENT_INVEST.route}>
                <Redirect to={CLIENT_INVEST_SIMPLE.route} />
              </Route>
              <Route path={CLIENT_INVEST_SIMPLE.route}>
                {/* <NavLink to={CLIENT_INVEST_ADVANCED.route} >{CLIENT_INVEST_ADVANCED.label}</NavLink> */}
                <SimpleInvestViewWrapped />
              </Route>
              <Route path={CLIENT_INVEST_ADVANCED.route}>
                <NavLink to={CLIENT_INVEST_SIMPLE.route} >{CLIENT_INVEST_SIMPLE.label}</NavLink>
                <AdvancedInvest />
              </Route>
              <Route path={CLIENT_INVEST_TRANCHE.route + '/:trancheId'} component={TrancheViewWrapped} />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}