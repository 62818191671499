import React from 'react';
import { QuantityCurrencyResult } from '../../domain';
import { InputMoney } from '../form/input-money';

interface QuantityCurrencyProps {
  currencies: Array<string>;
  value: QuantityCurrencyResult;
  onChange: (value: QuantityCurrencyResult) => void;
}

export class QuantityCurrency extends React.Component<QuantityCurrencyProps> {
  handleQuantityChange = (value: number) => {
    this.props.onChange({
      ...this.props.value,
      quantity: value
    })
  }
  handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChange({
      ...this.props.value,
      currency: event.target.value
    })
  }
  render() {
    return (
      <div className='row'>
        <div className='col-8'>
          <InputMoney value={this.props.value.quantity} onChange={this.handleQuantityChange} debounce={300} />
        </div>
        <div className='col-4'>
          <select className='form-control' onChange={this.handleCurrencyChange} value={this.props.value.currency}>
            {this.props.currencies.map(c => (<option key={c} value={c}>{c}</option>))}
          </select>
        </div>
      </div>
    )
  }
}

