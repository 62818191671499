import React from 'react';
import { Mine, ClientInvestment, QuantityCurrencyResult, SECURITY_TYPES, RiskParameters } from '../../../../domain';
import { InvestmentReturnsChart } from '../../../../components/confidence-graph';
import { formatNum, applyScale, getScale, generateInvestment, getExpectedReturns, formatReturn } from '../../../../util';
import { AppState } from '../../../../store';
import { connect } from 'react-redux';
import { SecurityGraph } from '../../../../components/security-graph';

import './style.css';
import { InputRiskSlider } from '../../../../components/investment/risk-slider';
import { BTCUSD } from '../../../../faker';

interface InvestmentViewProps {
  investment: ClientInvestment;
  simulationTime?: number;
  mine?: Mine;
  onBack: () => void;
  setPotentialInvestment: (investment: ClientInvestment) => void;
}

interface InvestmentViewState {
  riskTolerance: RiskParameters;
}

export class InvestmentView extends React.Component<InvestmentViewProps, InvestmentViewState> {
  constructor(props: InvestmentViewProps) {
    super(props);

    this.state = {
      riskTolerance: props.investment.actual.riskTolerance
    }
  }
  handleRiskToleranceChange = (value: RiskParameters) => {
    const { tranche, actual } = this.props.investment;
    const deposit: QuantityCurrencyResult = { quantity: actual.depositAmount, currency: actual.depositCurrency }
    this.props.setPotentialInvestment(
      generateInvestment(tranche, deposit, actual.termMs, value)
    );
    this.setState({ riskTolerance: value });
  }

  renderBtcSecurities() {
    const { investment } = this.props;
    const { tranche, calculation } = investment;
    const expectedReturn = getExpectedReturns(tranche.parameters, calculation.ownershipPercent);
    if (investment.securities.length === 0) {
      return (



        <div className='col-12 mt-4'>
          <strong>No securities have been selected for this investment</strong>
          <SecurityGraph
            quantity={expectedReturn}
            securities={[]}
            principal={investment.actual.depositAmount}
            asset='BTC'
            currency={investment.actual.depositCurrency}
            type={SECURITY_TYPES.OPTION_PUT}
            rangeMax={BTCUSD * 2}
            showLegend={true}
          />
        </div>
      )
    }

    return (
      <div className='row'>
        <div className='col-12'>
          <hr />
          <h5>Option [BTC PUT]</h5>
          <table className='table table-sm'>
            <thead>
              <tr>
                <th>Strike Price: &nbsp;</th>
                <th>Quantity</th>
                <th>Cost</th>
                <th>Minimum Sale Value</th>
                <th>Minimum Total Value</th>
              </tr>
            </thead>

            <tbody>
              {
                investment.securities.map(s =>
                  (
                    <tr key={s.uuid}>
                      <td>${formatNum(s.strikePrice)} USD</td>
                      <td>{formatNum(s.strikeQuantity)} {s.assetUnits}</td>
                      <td>${formatNum(s.cost)} USD</td>
                      <td>${formatNum(s.strikePrice * s.strikeQuantity)} USD</td>
                      <td>${formatNum(s.strikePrice * s.strikeQuantity - s.cost)} USD</td>
                    </tr>
                  )
                )
              }
            </tbody>
          </table>
        </div>

        <div className='col-12 mt-4'>
          {investment.securities.length > 0
            ? (<SecurityGraph
              quantity={expectedReturn}
              securities={investment.securities}
              principal={investment.actual.depositAmount}
              asset='BTC'
              currency={investment.actual.depositCurrency}
              type={SECURITY_TYPES.OPTION_PUT}
              showLegend={true}
              rangeMax={BTCUSD * 2}
            />)
            : null}
        </div>
      </div>

    );
  }
  render() {
    const { investment } = this.props;
    const { tranche, calculation } = investment;


    const deposit = investment.actual.depositAmount;
    const finalTick = investment.projectedReturns[investment.projectedReturns.length - 1];
    const returnEquiv = finalTick.value;
    const returnPercent = (returnEquiv / deposit - 1);
    const { parameters } = tranche;
    const { mine } = parameters;
    const expectedReturn = getExpectedReturns(parameters, calculation.ownershipPercent);
    const start = new Date(parameters.startDate);
    const maturity = new Date(parameters.startDate + parameters.term.approxMs);

    const scale = getScale([deposit, returnEquiv])
    const filled = 1 - tranche.remainingVolume / parameters.totalVolume
    return (
      <div>
        <div className='row'>
          <div className='col-12 mb-4'>
            <span className="btn btn-primary" onClick={this.props.onBack}>&lt;- Back</span>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='jumbotron'>
              <div className='col-12'>
                <h1>Investment</h1>
              </div>
              <div className='col-12 col-xl-11 offset-xl-1 mb-4'>
                <table className='table table-sm table-borderless text-primary h5'>
                  <tbody>
                    <tr><td>Crypto Mine:</td><td>{mine.title}</td></tr>
                    <tr><td>Tranche Subscribed:</td><td>${formatNum(tranche.remainingVolume)} / ${formatNum(parameters.totalVolume)} ({filled.toFixed(1)}% filled)</td></tr>
                    <tr><td>Investment Term:</td><td>{parameters.term.label}</td></tr>
                    <tr><td>Deposit: </td><td>USD ${applyScale(deposit, scale)}</td></tr>
                    <tr><td>Expected Mined BTC</td><td>{formatNum(expectedReturn)} BTC</td></tr>
                    <tr><td>Expected Returns: </td><td>USD ${applyScale(returnEquiv, scale)} ({formatReturn(returnPercent, parameters.term.approxMs)})</td></tr>
                    <tr><td>Invest Date: </td><td>{start.toDateString()}</td></tr>
                    <tr><td>Maturity Date: </td><td>{maturity.toDateString()}</td></tr>
                  </tbody>
                </table>
              </div>
              <div className='col-12'>
                <InvestmentReturnsChart
                  principal={deposit}
                  data={investment.projectedReturns}
                  simulationTime={this.props.simulationTime}
                  height={250}
                  actualReturns={this.props.investment.actualReturns}
                  showLegend={true}
                />
              </div>
              <div className='col-12'>
                <InputRiskSlider value={this.state.riskTolerance} onChange={this.handleRiskToleranceChange} />
              </div>
            </div>
          </div>
        </div>


        <div className="card border-secondary mb-3">
          <div className="card-body">
            <div className='row'>
              <div className='col-12'>
                <h3 className='text-black'>Securities</h3>
              </div>
            </div>
            {this.renderBtcSecurities()}
            <div className='row'>
              <div className='col-12'>
                <InputRiskSlider value={this.state.riskTolerance} onChange={this.handleRiskToleranceChange} />
              </div>
            </div>
          </div>
        </div>

        <span className="btn btn-primary" onClick={this.props.onBack}>&lt;- Back</span>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState, props: InvestmentViewProps) => {


  return {
    simulationTime: state.simulation.simulationTime,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
  }
}

export const InvestmentViewWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentView)
