import React from 'react';
import { InvestmentTrancheState, Mine } from '../../../../domain';
import { BTCUSD } from '../../../../faker/projections';
import { applyScale, getScale, getExpectedReturns } from '../../../../util';
import { AppState } from '../../../../store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import './style.css';

interface TrancheViewParams {
  trancheId: string;
}
interface TrancheViewProps extends RouteComponentProps<TrancheViewParams> {
  trancheId?: string;
  tranche?: InvestmentTrancheState;
  simulationTime?: number;
  mine?: Mine;
}

interface TrancheViewState {

}

export class TrancheView extends React.Component<TrancheViewProps, TrancheViewState> {
  render() {
    const { tranche, trancheId } = this.props;
    if (tranche === undefined) {
      return <label>Tranche not found: {trancheId}</label>
    }
    const { parameters } = tranche;
    const returnEquiv = getExpectedReturns(parameters) * BTCUSD;
    const returnPercent = returnEquiv / parameters.totalVolume;
    const start = new Date(parameters.startDate);
    const maturity = new Date(parameters.startDate + parameters.term.approxMs);

    const scale = getScale([parameters.totalVolume, returnEquiv, tranche.remainingVolume])

    return (
      <div>
        <div className='row'>
          <div className='col-12'>
            <div className='jumbotron'>
              <div className='col-12'>
                <h1>Investment Tranche</h1>
              </div>
              <div className='col-12 col-xl-11 offset-xl-1 mb-4'>
                <table className='table table-sm table-borderless text-primary h5'>
                  <tbody>
                    <tr><td>Crypto Mine:</td><td>{parameters.mine.title}</td></tr>
                    <tr><td>Investment Term:</td><td>{parameters.term.label}</td></tr>
                    <tr><td>Total Volume: </td><td>USD ${applyScale(parameters.totalVolume, scale)}</td></tr>
                    <tr><td>Remaining Volume:</td><td>USD ${applyScale(tranche.remainingVolume, scale)}</td></tr>
                    <tr><td>Expected Returns: </td><td>USD ${applyScale(returnEquiv, scale)} ({(returnPercent * 100 - 100).toFixed(1)}%)</td></tr>
                    <tr><td>Invest Date: </td><td>{start.toDateString()}</td></tr>
                    <tr><td>Maturity Date: </td><td>{maturity.toDateString()}</td></tr>
                  </tbody>
                </table>
              </div>
              <div className='col-12'>
                {/* <InvestmentReturnsChart
                  principal={parameters.totalVolume}
                  returnRate={returnPercent}
                  duration={parameters.term.approxMs}
                  startTime={parameters.startDate}
                  variance={parameters.expectedVariance}
                  returnPeriod={parameters.term.approxMs}
                  compoundType={COMPOUND_TYPE.LINEAR}
                  simulationTime={this.props.simulationTime}
                  height={300}
                /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="card text-white bg-primary mb-3 mt-5" key={parameters.uuid}>
          <div className="card-body">
            <form className='row'>
              <div className='col-12'>
                <h3 className='text-white'>Invest Now</h3>
              </div>
              <div className='col-sm-8'>

                <label>Amount</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">USD $</span>
                  </div>
                  <input type='number' className='form-control' aria-label="Amount (to the nearest dollar)" />
                  <div className="input-group-append">
                    <span className="input-group-text">.00</span>
                  </div>
                </div>
              </div>
              <div className='col-sm-4'>
                <label>&nbsp;</label>
                <button className='btn btn-secondary btn-block'>Invest</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState, props: TrancheViewProps) => {
  const { trancheId } = props.match.params;
  const tranche = state.mines.availableTranches.find(t => t.parameters.uuid === trancheId);

  return {
    trancheId: trancheId,
    tranche: tranche,
    simulationTime: state.simulation.simulationTime
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
  }
}

export const TrancheViewWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrancheView)
