import React from 'react';
import { PortfolioView } from './portfolio-view';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { InvestView } from './invest-view';
import { CLIENT_VIEW, CLIENT_INVEST, CLIENT_PORTFOLIO } from '../../const/routes';

interface ClientViewProps {

}

interface ClientViewState {
}

export class ClientView extends React.Component<ClientViewProps, ClientViewState>  {
  render() {
    return (
      <div>
        {/* <ul className="nav nav-tabs" >
          <NavLink className='nav-link' activeClassName='active' to={CLIENT_INVEST.route} >{CLIENT_INVEST.label}</NavLink>
          <NavLink className='nav-link' activeClassName='active' to={CLIENT_PORTFOLIO.route} >{CLIENT_PORTFOLIO.label}</NavLink>
        </ul> */}
        <div id="myTabContent" className="tab-content" style={{ paddingTop: 20, paddingBottom: 10 }}>
          <Switch>
            <Route exact path={CLIENT_VIEW.route}>
              <Redirect to={CLIENT_INVEST.route} />
            </Route>
            <Route path={CLIENT_INVEST.route}>
              <InvestView />
            </Route>
            <Route path={CLIENT_PORTFOLIO.route}>
              <PortfolioView />
            </Route>
          </Switch>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    simulationTime: state.simulation.simulationTime,
    mode: state.simulation.mode,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
  }
}

export const ClientViewWrapped = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientView))
