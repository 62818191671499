import React, { RefObject } from 'react';
import echarts, { ECharts } from 'echarts';


interface SecurityGraphProps {
  height: number;
  options: echarts.EChartOption
}

export class DisplayChart extends React.Component<SecurityGraphProps>  {
  chartRef: RefObject<HTMLDivElement>;
  chart?: ECharts;
  series: any;

  static defaultProps = {
    height: 200,
  }
  constructor(props: SecurityGraphProps) {
    super(props);

    this.chartRef = React.createRef();
  }
  updateChart(props: SecurityGraphProps) {
    if (this.chart !== undefined) {
      this.chart.setOption(props.options, true);
    }
  }
  componentDidMount() {
    var dom: HTMLDivElement | null = this.chartRef.current;// document.getElementById(id) as HTMLDivElement;
    if (dom !== null) {

      var myChart = echarts.init(dom);
      this.chart = myChart;

      this.updateChart(this.props);
    }
  }

  render() {
    const { height } = this.props;

    this.updateChart(this.props);

    return (
      <div ref={this.chartRef} style={{ height: height }}>

      </div>
    )
  }
}
