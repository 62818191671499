import { MineActionTypes, MineState } from ".";
import { RECEIVE_TRANCHES, CLAIM_TRANCHE, RECEIVE_MINES } from "./types";
import { fakeMines } from "../../faker/mines";
import { fakeTranches } from "../../faker/tranches";

const mines = fakeMines(10);

const initialState: MineState = {
  availableTranches: fakeTranches(20, mines).map(t => ({ parameters: t, remainingVolume: t.totalVolume })),
  clientClaims: [],
  mines: mines,
}

export function mineReducer(
  state = initialState,
  action: MineActionTypes
): MineState {
  switch (action.type) {
    case RECEIVE_MINES:
      return {
        ...state,
        mines: action.payload
      }
    case RECEIVE_TRANCHES:
      return {
        ...state,
        availableTranches: [
          ...state.availableTranches,
          ...action.payload.map(t => (
            {
              parameters: t,
              remainingVolume: t.totalVolume
            }))
        ]
      }
    case CLAIM_TRANCHE:
      return {
        ...state,
        availableTranches: state.availableTranches.map(t => (
          (t.parameters.uuid === action.payload.trancheId)
            ? {
              ...t,
              remainingVolume: t.remainingVolume - action.payload.volume
            }
            : t
        )),
        clientClaims: [...state.clientClaims, action.payload]
      }
    default:
      return state;
  }
}