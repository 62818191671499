import React from 'react';
import { InvestmentTrancheState } from '../../../../../domain';
import { BTCUSD } from '../../../../../faker';
import { formatNum, getExpectedReturns } from '../../../../../util';
import { NavLink } from 'react-router-dom';
import { CLIENT_INVEST_TRANCHE } from '../../../../../const/routes';

interface TrancheProps {
  tranche: InvestmentTrancheState;
  simulationTime: number;
}

interface TrancheState {

}

export class Tranche extends React.Component<TrancheProps, TrancheState> {
  render() {
    const { tranche } = this.props;
    const { parameters } = tranche;
    const returnEquiv = getExpectedReturns(parameters) * BTCUSD;
    const start = new Date(parameters.startDate);

    return (
      <div className="card border-primary mb-3" key={parameters.uuid}>
        <div className="card-header">
          {formatNum(parameters.totalVolume)} USD, {parameters.term.label}. [{start.toDateString()}]
        </div>
        <div className="card-body">
          <div className='row'>
            <div className='col-md-6 card-text'>
              <div className='row'>
                <div className='col-12'>
                  <table className='table table-sm'>
                    <tbody>
                      <tr>
                        <td>Upper Returns: </td><td>${formatNum(returnEquiv * (1.1))} USD</td>
                      </tr>
                      <tr>
                        <td>Expected Returns: </td><td>${formatNum(returnEquiv)} USD</td>
                      </tr>
                      <tr>
                        <td>Lower Returns: </td><td>${formatNum(returnEquiv * (0.99))} USD</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='col-12'>
                  <form className='row'>
                    <div className='col-sm-8'>
                      <label>Amount</label>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">USD $</span>
                        </div>
                        <input type='number' className='form-control' aria-label="Amount (to the nearest dollar)" />
                        <div className="input-group-append">
                          <span className="input-group-text">.00</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <label>&nbsp;</label>
                      <button className='btn btn-primary btn-block'>Invest</button>
                    </div>
                  </form>
                </div>
                <div className='col-12'>
                  <NavLink className='btn btn-outline-info  btn-block' to={CLIENT_INVEST_TRANCHE.route + '/' + parameters.uuid}>
                    More Information
                  </NavLink>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              {/* <InvestmentReturnsChart
                principal={parameters.totalVolume}
                returnRate={returnPercent}
                duration={parameters.term.approxMs}
                startTime={parameters.startDate}
                variance={parameters.expectedVariance}
                returnPeriod={parameters.term.approxMs}
                compoundType={COMPOUND_TYPE.LINEAR}
                simulationTime={this.props.simulationTime}
                height={310}
              /> */}
            </div>

          </div>

        </div>
      </div >
    )
  }
}
