import React from 'react';
import { formatThousands } from '../../util';

interface InputNumberProps {
  label?: string;
  debounce: number;
  value: number;
  onChange: (value: number) => void;
}

interface InputNumberState {
  tempVal: number;
}

export class InputNumber extends React.Component<InputNumberProps, InputNumberState> {
  static defaultProps = {
    debounce: 200
  }
  timer?: any;
  constructor(props: InputNumberProps) {
    super(props);

    this.state = {
      tempVal: props.value
    }
  }
  propagate = () => {
    this.props.onChange(this.state.tempVal);
  }
  setPropagateTimer() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.propagate, this.props.debounce);
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ tempVal: parseFloat(event.target.value.replace(/\D/g, '')) });
    this.setPropagateTimer();
  }
  renderLabel() {
    const { label } = this.props;
    if (label === undefined) {
      return null;
    }
    return (<label>{label}</label>)
  }
  render() {
    return (
      <div className='form-group'>
        {this.renderLabel()}
        <input className='form-control' type='text' value={formatThousands(this.state.tempVal)} onChange={this.handleChange} />
      </div>
    )
  }
}