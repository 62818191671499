import React from 'react';
import './index.css';
import './styles/bootstrap.min.css';
import './styles/open-iconic/css/open-iconic.min.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './store'
import thunk from 'redux-thunk';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactTooltip from 'react-tooltip'

const store = createStore(rootReducer, applyMiddleware(thunk));


render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
    <ReactTooltip />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
