import { DataPoint } from "../domain";

export const rand = (max: number = 1, min: number = 0) => {
  if (min > max) {
    const tmp = max;
    max = min;
    min = tmp;
  }
  return Math.random() * (max - min) + min;
}

export const randInt = (max: number = 1, min: number = 0) => Math.floor(rand(max, min));

export const randTimeSeries = (minVal: number, maxVal: number, minTime: number, maxTime: number, points: number, firstVal?: number): Array<DataPoint> => {
  const data: Array<DataPoint> = [];

  for (let i = 0; i < points; i++) {
    data.push({
      timestamp: (i / (points - 1)) * (maxTime - minTime) + minTime,
      value: (i === 0 && firstVal !== undefined ? firstVal : rand(maxVal, minVal))
    })
  }
  return data;
}