export const SECURITY_TYPES = {
  OPTION_PUT: 'OPTION_PUT',
  OPTION_CALL: 'OPTION_CALL',
  FUTURE: 'FUTURE'
}

export interface Security {
  type: string;
  asset: string;
  assetUnits: string;
  currency: string;
  strikePrice: number;
  strikeQuantity: number;
  strikeQuantityRemaining: number;
  cost: number;
  uuid: string;
}