export const BASIC_COLORS = {
  RED: '#c23531',
  BLUE: '#008ECC',
  GREEN: '#55AA33',
  BLACK: '#000000',
  GREY: '#777',
  LIGHT_GREY: '#DDD'
}

export const BRAND_COLORS = {
  PRIMARY: BASIC_COLORS.BLUE,
}

export const GRAPH_COLORS = {
  UNHEDGED_RETURNS: BASIC_COLORS.GREY,
  HEDGED_RETURNS: BASIC_COLORS.BLUE,
  PROJECTED_RETURNS: BASIC_COLORS.BLUE,
  ACTUAL_RETURNS: BASIC_COLORS.GREEN,
  ACTUAL_COST: BASIC_COLORS.RED,
  BREAK_EVEN: BASIC_COLORS.BLACK,
  PROBABILITY_DISTRIBUTION: BASIC_COLORS.LIGHT_GREY
}