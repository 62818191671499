import React from 'react';

export class PortfolioView extends React.Component {
  render() {
    return (
      <div>
        <h3>Portfolio View</h3>
      </div>
    )
  }
}

