export interface RouteParam {
  route: string,
  label: string,
}

const pathJoin = (a: string, b: string) => {
  return a + b;
}

export const MINER_VIEW: RouteParam = {
  label: 'Miner View',
  route: '/miner-view'
}

export const CLIENT_VIEW: RouteParam = {
  label: 'Client View',
  route: '/client-view'
}

export const CLIENT_INVEST: RouteParam = {
  label: 'Invest',
  route: pathJoin(CLIENT_VIEW.route, '/invest')
}

export const CLIENT_INVEST_SIMPLE = {
  route: pathJoin(CLIENT_INVEST.route, '/simple'),
  label: 'Simple Mode'
}

export const CLIENT_INVEST_ADVANCED = {
  route: pathJoin(CLIENT_INVEST.route, '/advanced'),
  label: 'Advanced Mode'
}

export const CLIENT_INVEST_TRANCHE = {
  route: pathJoin(CLIENT_INVEST.route, '/tranche'),
  label: 'Tranche'
}

export const CLIENT_PORTFOLIO: RouteParam = {
  label: 'My Portfolio',
  route: pathJoin(CLIENT_VIEW.route, '/my-protfolio')
}

export const SIMULATION_PARAMETERS: RouteParam = {
  label: 'Simulation Parameters',
  route: '/simulation-parameters'
}
