import { ClientInvestment } from "../../domain";
import { CreateClientInvestment, CREATE_INVESTMENT, SetPotentialInvestment, SET_POTENTIAL_INVESTMENT } from "./types";

export const createClientInvestment = (investment: ClientInvestment): CreateClientInvestment => ({
  type: CREATE_INVESTMENT,
  payload: investment
})

export const setPotentialInvestment = (investment?: ClientInvestment): SetPotentialInvestment => ({
  type: SET_POTENTIAL_INVESTMENT,
  payload: investment
})