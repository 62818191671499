import { randInt } from "../util"

const firstNames = [
  'Total',
  'Capital',
  'Crypto',
  'Bridge',
  'Trident',
  'True',
  'X-Fer',
  'AnalytIQ',
  'Advanced',
  'Royal'
]

const lastNames = [
  'Engine',
  'Miner',
  'Water',
  'TI',
  'Services',
  'Group',
  'Inc.',
  'Co.',
  'Flow',
  'Rock'
]


export const fakeName = () => {
  const f = randInt(0, firstNames.length);
  const l = randInt(0, lastNames.length);
  return `${firstNames[f]} ${lastNames[l]}`
}