import React from 'react';
import Slider from 'rc-slider';
import { BRAND_COLORS } from '../../const';
import ReactTooltip from 'react-tooltip';

interface Marks {
  [value: number]: { style: any, label: string }
}

interface InputSliderProps {
  label?: string;
  debounce: number;
  value: number;
  rescale: number;
  min: number;
  max: number;
  availableMin?: number;
  availableMax?: number;
  showVal?: boolean;
  onChange: (value: number) => void;
  marks?: Marks;
  description?: string;
}

interface InputSliderState {
  tempVal: number;
  initVal: number;
}


export class InputSlider extends React.Component<InputSliderProps, InputSliderState> {
  static defaultProps = {
    debounce: 200,
    rescale: 1,
  }
  timer?: any;
  constructor(props: InputSliderProps) {
    super(props);


    this.state = {
      tempVal: props.value,
      initVal: props.value
    }
  }
  static getDerivedStateFromProps(props: InputSliderProps, state: InputSliderState): InputSliderState {
    if (props.value !== state.initVal) {
      return {
        ...state,
        tempVal: props.value,
        initVal: props.value
      };
    }
    return state;
  }
  propagate = () => {
    this.props.onChange(this.state.tempVal);
  }
  setPropagateTimer() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.propagate, this.props.debounce);
  }
  handleChange = (value: number) => {
    let v = value / this.props.rescale;
    if (this.props.availableMin !== undefined && v < this.props.availableMin) {
      v = this.props.availableMin;
    }
    if (this.props.availableMax !== undefined && v > this.props.availableMax) {
      v = this.props.availableMax;
    }

    this.setState({ tempVal: v });
  }
  handleAfterChange = (value: number) => {
    this.setPropagateTimer();
  }
  renderLabel() {
    const { label } = this.props;
    if (label === undefined) {
      return null;
    }
    return (<label data-tip={this.props.description}>
      {label}
    </label>)
  }
  renderValue() {
    if (this.props.showVal === true) {
      return (<label>&nbsp;[{this.state.tempVal}]</label>)
    }
  }
  getMarks(): Marks {
    if (this.props.marks === undefined) {
      return {};
    }
    const marks: Marks = {};
    for (const v of Object.keys(this.props.marks)) {
      const vNum = Number(v);
      marks[vNum * this.props.rescale] = this.props.marks[vNum];
    }
    return marks;
  }
  render() {
    const style: React.CSSProperties = {
      color: BRAND_COLORS.PRIMARY,
      borderColor: BRAND_COLORS.PRIMARY,
      backgroundColor: BRAND_COLORS.PRIMARY
    }
    return (
      <div className='form-group mb-5'>
        {this.renderLabel()}
        {this.renderValue()}
        <Slider
          min={this.props.min * this.props.rescale}
          max={this.props.max * this.props.rescale}
          value={this.state.tempVal * this.props.rescale}
          onChange={this.handleChange}
          onAfterChange={this.handleAfterChange}
          marks={this.getMarks()}
          dotStyle={{ display: 'none' }}
          trackStyle={style}
          handleStyle={style}
        />
        <ReactTooltip />
      </div>
    );
  }
}