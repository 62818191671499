export const normalDistribution = (x: number, mean: number = 0, stddev: number = 1) => {
  return (
    1 / (
      Math.sqrt(
        2 * Math.PI * Math.pow(stddev, 2)
      )
    )
  ) *
    Math.exp(
      -Math.pow(x - mean, 2) / (2 * Math.pow(stddev, 2))
    )
}

export const logNormalDistribution = (x: number, mean: number = 0, stddev: number = 1) => {
  if (x <= 0) {
    return 0;
  }
  const percent = (
    1 / (
      x * stddev *
      Math.sqrt(
        2 * Math.PI
      )
    )
  ) *
    Math.exp(
      -Math.pow(Math.log(x) - mean, 2) /
      (2 * Math.pow(stddev, 2))
    )
  return percent;
}
