import { InvestmentTrancheState, ClientInvestment, ClientInvestmentParameters, QuantityCurrencyResult, SECURITY_TYPES, ConfidencePoint, InvestmentTranche, Security, ClientInvestmentCalculation, RiskParameters } from "../domain";
import { getSecurities, BTCUSD, projectBtcUsd, getBtcUsdUncertainty, getElectrictyUsdUncertainty } from "../faker";
import { ulid } from "ulid";
import { getConfidenceAt } from ".";

export const getExpectedReturns = (trancheParameters: InvestmentTranche, percent: number = 1) => {
  if (trancheParameters.expectedReturns.length > 0) {
    return trancheParameters.expectedReturns[trancheParameters.expectedReturns.length - 1].value * percent;
  }
  return 0;
}

export const getClientInvestmentCalculation = (tranche: InvestmentTrancheState, deposit: number, riskTolerance: RiskParameters): ClientInvestmentCalculation => {
  const { parameters } = tranche;
  const { term } = parameters;
  const btcUncertainty = getBtcUsdUncertainty(term.approxMs);
  const pwrUncertainty = getElectrictyUsdUncertainty(term.approxMs);
  const ownershipPercent = deposit / parameters.totalVolume;
  const strikePrice = BTCUSD * (1 - riskTolerance.strikeFromMarketPercent);

  const btcHedgeQty = ownershipPercent * getExpectedReturns(parameters) * (1 - riskTolerance.hedgeDownsidePercent);

  return {
    btcUncertainty,
    pwrUncertainty,
    riskTolerance,
    btcHedgeQty,
    ownershipPercent,
    strikePrice
  }
}

export const calculateReturnsGraph = (tranche: InvestmentTranche, ownership: number, securities: Array<Security>, points: number): Array<ConfidencePoint> => {
  const retval: Array<ConfidencePoint> = [];

  const termMs = tranche.term.approxMs;
  securities.sort((a, b) => {
    if (a.strikePrice < b.strikePrice) {
      return 1;
    }
    if (a.strikePrice > b.strikePrice) {
      return -1;
    }
    return 0
  });

  let securitiesCostTotal = 0;
  let strikePriceMax = 0;
  let securitiesQuantityTotal = 0;

  for (const s of securities) {
    securitiesCostTotal += s.cost;
    securitiesQuantityTotal += s.strikeQuantity;
    if (s.strikePrice > strikePriceMax) {
      strikePriceMax = s.strikePrice;
    }
  }

  for (let i = 0; i <= points; i++) {
    const ratio = (i / points);
    const timePassed = ratio * termMs;
    const ts = timePassed + tranche.startDate;
    const minedCrypto = getConfidenceAt(ts, tranche.expectedReturns);
    const btcUsd = projectBtcUsd(ts);
    const btcUsdSecured: ConfidencePoint = {
      timestamp: ts,
      upper: btcUsd.upper > strikePriceMax ? btcUsd.upper : strikePriceMax,
      value: btcUsd.value > strikePriceMax ? btcUsd.value : strikePriceMax,
      lower: btcUsd.lower > strikePriceMax ? btcUsd.lower : strikePriceMax
    }
    const mined = minedCrypto.value * ownership;
    const hedgedCrypto = (mined <= securitiesQuantityTotal ? mined : securitiesQuantityTotal)
    const unHegedCrypto = (mined <= securitiesQuantityTotal ? 0 : mined - securitiesQuantityTotal)
    const v = {
      timestamp: ts,
      value: (unHegedCrypto * btcUsd.value) + (hedgedCrypto * btcUsdSecured.value) - securitiesCostTotal,
      upper: (unHegedCrypto * btcUsd.upper) + (hedgedCrypto * btcUsdSecured.upper) - securitiesCostTotal,
      lower: (unHegedCrypto * btcUsd.lower) + (hedgedCrypto * btcUsdSecured.lower) - securitiesCostTotal,
    }
    retval.push(v);
  }

  return retval;
}


export const generateInvestment = (t: InvestmentTrancheState, deposit: QuantityCurrencyResult, termMs: number, riskTolerance: RiskParameters): ClientInvestment => {

  const calc = getClientInvestmentCalculation(t, deposit.quantity, riskTolerance);

  console.log(calc)
  const btcOptions = getSecurities(SECURITY_TYPES.OPTION_PUT, 'BTC', '', deposit.currency, BTCUSD, calc.strikePrice, calc.btcHedgeQty, termMs);

  const investmentParameters: ClientInvestmentParameters = {
    depositAmount: deposit.quantity,
    depositCurrency: deposit.currency,
    termMs: termMs,
    riskTolerance: riskTolerance,
    returnCurrency: deposit.currency,
    expectedReturnAmount: 0 // TODO get from tranche
  }

  return {
    tranche: t,
    calculation: calc,
    uuid: ulid(),
    securities: btcOptions,
    preference: investmentParameters,
    actual: investmentParameters,
    projectedReturns: calculateReturnsGraph(t.parameters, calc.ownershipPercent, btcOptions, 20),
    actualReturns: []
  };
}
