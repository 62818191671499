import React from 'react';
import { QuantityCurrency } from '../../../../components/quantity-currency';
import { AppState } from '../../../../store';
import { ClientInvestment, InvestmentTrancheState, QuantityCurrencyResult, RiskParameters } from '../../../../domain';
import { createClientInvestment, setPotentialInvestment } from '../../../../store';
import { connect } from 'react-redux';
import { ONE_DAY, ONE_YEAR, ONE_MONTH } from '../../../../const/time';
import { InvestmentReturnsChart } from '../../../../components/confidence-graph';
import 'rc-slider/assets/index.css';
import { formatDate, generateInvestment, formatReturn } from '../../../../util';
import { InvestmentViewWrapped } from '../investment-view';
import { InputRiskSlider, RiskSliderDefaults } from '../../../../components/investment/risk-slider';
import { TERMS } from '../../../../const/term';
import { InputSelect } from '../../../../components/form/input-select';
import { BRAND_COLORS } from '../../../../const';

interface SimpleInvestViewProps {
  minimumRisk: number;
  simulationTime: number;
  simulationStart: number;
  availableTranches: Array<InvestmentTrancheState>;
  setPotentialInvestment: (investment?: ClientInvestment) => void;
  potentialInvestment?: ClientInvestment;
}

interface SimpleInvestViewState {
  deposit: QuantityCurrencyResult;
  riskTolerance: RiskParameters;
  depositTerm: number;
  displayTranches: Array<ClientInvestment> | null;
  loading: boolean;
  returnCurrency: string;
}

const currencies = [
  'USD',
  'EUR',
  'BTC',
  'ETH'
]

const depositTerm = [
  {
    label: 'One Month',
    value: 30 * ONE_DAY,
  },
  {
    label: '6 Months',
    value: 6 * ONE_MONTH,
  },
  {
    label: '12 Months',
    value: ONE_YEAR
  }
]

export class SimpleInvestView extends React.Component<SimpleInvestViewProps, SimpleInvestViewState> {
  constructor(props: SimpleInvestViewProps) {
    super(props);

    this.state = {
      deposit: {
        currency: currencies[0],
        quantity: 10000
      },
      riskTolerance: {
        strategy: RiskSliderDefaults.strategy,
        earlyLiquidation: RiskSliderDefaults.earlyLiquidation,
        strikeFromMarketPercent: RiskSliderDefaults.strikeFromMarketPercent,
        hedgeDownsidePercent: RiskSliderDefaults.hedgeDownsidePercent,
        hedgeUpsidePercent: RiskSliderDefaults.hedgeUpsidePercent,
        liquidationPercent: RiskSliderDefaults.liquidationPercent
      },
      depositTerm: depositTerm[2].value,
      displayTranches: null,
      loading: true,
      returnCurrency: currencies[0]
    }
  }

  componentDidMount() {
    this.filterTranches();
  }

  handleDepositChange = (value: QuantityCurrencyResult) => {
    this.setState({ deposit: value });
    this.filterTranches();
  }

  handleRiskToleranceChange = (value: RiskParameters) => {
    this.setState({ riskTolerance: value })
    this.filterTranches();
  }

  handleDepositTermChange = (value: any) => {
    this.setState({ depositTerm: parseInt(value) })
    this.filterTranches();
  }

  handleReturnCurrencyChange = (value: any) => {
    this.setState({ returnCurrency: value })
  }

  handleInvestClick(investment: ClientInvestment) {
    this.props.setPotentialInvestment(investment);
  }

  handleInvestBack = () => {
    this.props.setPotentialInvestment(undefined);
  }

  filterTranches() {
    this.setState({ loading: true });
    setTimeout(() => {
      const { deposit, depositTerm, riskTolerance } = this.state;
      const sortTranches = (a: InvestmentTrancheState, b: InvestmentTrancheState) => {
        if (a.parameters.startDate > b.parameters.startDate) {
          return 1;
        }
        if (a.parameters.startDate < b.parameters.startDate) {
          return -1;
        }
        return 0;
      }
      this.setState(
        {
          loading: false,
          displayTranches:
            this.props.availableTranches
              .filter(t => t.parameters.term.approxMs === depositTerm)
              .sort(sortTranches)
              .map((t: InvestmentTrancheState): ClientInvestment => (generateInvestment(t, deposit, depositTerm, riskTolerance)))
        }
      );
    }, 100);
  }

  renderTranches() {
    if (this.state.displayTranches === null) {
      return <label>Loading...</label>;
    }


    let rangeMin = this.state.displayTranches.reduce((prev: number, curr: ClientInvestment) => {
      const min = curr.projectedReturns[0].lower;
      return (prev === Infinity || min < prev ? min : prev)
    }, Infinity) * 1.05;
    let rangeMax = this.state.displayTranches.reduce((prev: number, curr: ClientInvestment) => {
      const max = curr.projectedReturns[curr.projectedReturns.length - 1].upper;
      return (prev === -Infinity || max > prev ? max : prev)
    }, -Infinity) * 1.05;

    return (
      <div className='row'>
        {
          this.state.displayTranches.map((investment: ClientInvestment) => {
            const { tranche } = investment;

            const { parameters } = tranche;
            const { deposit, loading } = this.state;
            const { term, startDate } = parameters;

            const finalTick = investment.projectedReturns[investment.projectedReturns.length - 1];
            const returnEquiv = finalTick.value;
            const returnPercent = returnEquiv / deposit.quantity - 1;

            return (
              <div className='col-md-6 mb-5' key={parameters.uuid}>
                <h6 className={loading ? 'text-muted' : ''}>
                  {formatDate(startDate)}<br />{term.label} [Est. {formatReturn(returnPercent, term.approxMs)}]
                </h6>
                <InvestmentReturnsChart
                  principal={deposit.quantity}
                  data={investment.projectedReturns}
                  currency={deposit.currency}
                  currencySymbol='$'
                  rangeMin={Number(rangeMin.toPrecision(2))}
                  rangeMax={Number(rangeMax.toPrecision(2))}
                />
                <button
                  className='btn btn-info btn-sm btn-block'
                  type='button'
                  onClick={this.handleInvestClick.bind(this, investment)}
                  disabled={loading}
                  style={{ backgroundColor: BRAND_COLORS.PRIMARY }}
                >
                  Invest in this Tranche
                </button>
              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    const { potentialInvestment } = this.props;
    if (potentialInvestment !== undefined) {
      return <InvestmentViewWrapped
        onBack={this.handleInvestBack}
        investment={potentialInvestment}
        setPotentialInvestment={this.handleInvestClick.bind(this)}
      />
    }
    return (
      <div className='mb-5'>
        <h3 className='mb-3'>Simple Invest Mode</h3>
        <form>
          <fieldset>
            <div className='form-group'>
              <label>Deposit</label>
              <QuantityCurrency currencies={currencies} value={this.state.deposit} onChange={this.handleDepositChange} />
            </div>

            <InputRiskSlider label='Risk Tolerance' value={this.state.riskTolerance} onChange={this.handleRiskToleranceChange} />
            <div className='row'>
              <div className='col-md-6'>
                <InputSelect label={'Term'} options={TERMS.map(t => ({ value: t.approxMs, label: t.label }))} value={this.state.depositTerm} onChange={this.handleDepositTermChange} />
              </div>
              <div className='col-md-6'>
                <InputSelect label={'Return Currency'} options={currencies.map(c => ({ value: c, label: c }))} value={this.state.returnCurrency} onChange={this.handleReturnCurrencyChange} />
              </div>
            </div>
            <div className='form-group row mt-5'>
              <div className='col-12'>
                <h3>Available Tranches</h3>
                <label>Select a tranche with your desired start date.</label>
              </div>
            </div>
            {this.renderTranches()}
          </fieldset>
        </form>
      </div >
    )
  }
}


const mapStateToProps = (state: AppState) => {
  return {
    minimumRisk: 0.0,
    investments: state.clientInvestment.investments,
    simulationTime: state.simulation.simulationTime,
    simulationStart: state.simulation.parameters.startTimestamp,
    availableTranches: state.mines.availableTranches,
    potentialInvestment: state.clientInvestment.potentialInvestment
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    onInvest: (investment: ClientInvestment) => { dispatch(createClientInvestment(investment)) },
    setPotentialInvestment: (investment?: ClientInvestment) => dispatch(setPotentialInvestment(investment))
  }
}

export const SimpleInvestViewWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleInvestView)
