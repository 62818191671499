import React from 'react';
import { NavLink } from 'react-router-dom'
import classnames from "classnames";
import { CLIENT_VIEW, SIMULATION_PARAMETERS, MINER_VIEW } from '../../const/routes';
import Logo from '../../logo.png'
import './style.css'
interface NavigationBarProps {

}

interface NavigationBarState {
  openHandle: boolean
}

export class NavigationBar extends React.Component<NavigationBarProps, NavigationBarState> {
  constructor(props: NavigationBarProps) {
    super(props);

    this.state = {
      openHandle: false
    }
  }
  handleMenuClick = () => {
    this.setState({ openHandle: !this.state.openHandle })
  }
  render() {
    const defaultStyle = { color: '#BBB' }
    const activeStyle = { color: '#000' }
    return (

      <div className="navbar navbar-expand-lg fixed-top navbar-light bg-light" >
        <div className="container">
          <a href="../" className="navbar-brand"><img alt='logo' src={Logo} style={{ height: 50 }} /></a>
          <button onClick={this.handleMenuClick} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={classnames("collapse", "navbar-collapse", { 'show': this.state.openHandle })} id="navbarResponsive">
            <ul className="navbar-nav text-right">
              <li className="nav-item">
                <NavLink style={defaultStyle} activeStyle={activeStyle} to={CLIENT_VIEW.route} >{CLIENT_VIEW.label}</NavLink>
              </li>
              <li className="nav-item">
                <NavLink style={defaultStyle} activeStyle={activeStyle} to={MINER_VIEW.route} >{MINER_VIEW.label}</NavLink>
              </li>
              <li className="nav-item" style={{ color: '#777' }}>
                <NavLink style={defaultStyle} activeStyle={activeStyle} to={SIMULATION_PARAMETERS.route}>{SIMULATION_PARAMETERS.label}</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}