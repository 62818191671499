import { clientInvestmentReducer } from "./client-investment"
import { combineReducers } from "redux"
import { simulationReducer } from "./simulation"
import { mineReducer } from "./mine"

export * from './client-investment';
export * from './mine';
export * from './simulation';

export const rootReducer = combineReducers({
  clientInvestment: clientInvestmentReducer,
  simulation: simulationReducer,
  mines: mineReducer
})

export type AppState = ReturnType<typeof rootReducer>