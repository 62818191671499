import { DataPoint, SECURITY_TYPES, MiningReturns, ConfidencePoint, Security } from "../domain";
import { ONE_SECOND, ONE_YEAR } from "../const";
import { COMPOUND_TYPE } from "../const/compound-type";

export const getValueAt = (timestamp: number, data: Array<DataPoint | ConfidencePoint>) => {

  if (timestamp < data[0].timestamp) {
    return data[0].value;
  }
  if (timestamp > data[data.length - 1].timestamp) {
    return data[data.length - 1].value;
  }
  for (let i = 0; i < data.length - 1; i++) {
    const a = data[i];
    const b = data[i + 1];
    if (timestamp === a.timestamp) {
      return a.value;
    }
    if (timestamp === b.timestamp) {
      return b.value
    }
    if (timestamp > a.timestamp && timestamp < b.timestamp) {
      const deltaTime = b.timestamp - a.timestamp;
      const deltaValue = b.value - a.value;
      const ratioTime = (timestamp - a.timestamp) / deltaTime;
      return (deltaValue * ratioTime) + a.value;
    }
  }
  throw new Error("Timestamp out of range");
}
export const getConfidenceAt = (timestamp: number, data: Array<ConfidencePoint>): ConfidencePoint => {

  if (timestamp < data[0].timestamp) {
    return data[0];
  }
  if (timestamp > data[data.length - 1].timestamp) {
    return data[data.length - 1];
  }
  for (let i = 0; i < data.length - 1; i++) {
    const a = data[i];
    const b = data[i + 1];
    if (timestamp === a.timestamp) {
      return a;
    }
    if (timestamp === b.timestamp) {
      return b
    }
    if (timestamp > a.timestamp && timestamp < b.timestamp) {
      const deltaTime = b.timestamp - a.timestamp;
      const ratioTime = (timestamp - a.timestamp) / deltaTime;
      return {
        timestamp: timestamp,
        upper: (b.upper - a.upper) * ratioTime + a.upper,
        value: (b.value - a.value) * ratioTime + a.value,
        lower: (b.lower - a.lower) * ratioTime + a.lower,
      }
    }
  }
  throw new Error("Timestamp out of range");
}


export const calculateRevenuePerSec = (params: MiningParameters) => {
  return (params.hashPower / (65536 * 65536 * params.miningDifficulty)) * params.blockReward;
}

export interface MiningParameters {
  hashPower: number;
  miningDifficulty: number;
  blockReward: number;
}

export const calculateRevenueInTick = (tickDuration: number, miningParams: MiningParameters) => {
  return calculateRevenuePerSec(miningParams) * (tickDuration / ONE_SECOND);
}

export const compoundReturns = (compoundType: string, principal: number, interest: number, ratio: number) => {
  switch (compoundType) {
    case COMPOUND_TYPE.LINEAR:
      return principal * interest * ratio;
    case COMPOUND_TYPE.CONTINUOUS_EXPONENTIAL:
      return principal * (Math.exp(interest * ratio) - 1);
  }
  throw new Error(`Unknown compoundType: ${compoundType}`);
}

export interface MiningReturnsParameters {
  from: number,
  to: number,
  hashingPowerInTH: number,
  pwrConsumptionInW: number,
  pwrCostPerKWH: number,
  blockReward: number,
  miningDifficultyInT: number
}
export const getMiningReturns = (params: MiningReturnsParameters): MiningReturns => {
  const projectedMiningRatePerSec = calculateRevenuePerSec({ hashPower: params.hashingPowerInTH * 1e12, blockReward: params.blockReward, miningDifficulty: params.miningDifficultyInT * 1e12 });
  const timespan = params.to - params.from;
  const seconds = (timespan / ONE_SECOND);
  const expectedBtc = projectedMiningRatePerSec * seconds;
  const electricityCostPerWS = (params.pwrCostPerKWH / 1000) / (60 * 60);
  const pwrCost = params.pwrConsumptionInW * electricityCostPerWS * seconds;

  return {
    from: params.from,
    to: params.to,
    cryptoMined: expectedBtc,
    electricityCost: pwrCost
  }
}

export const getSecuritizedSale = (quantity: number, marketRate: number, securities: Array<Security>) => {
  let remainingQuantity = quantity;
  let totalSaleValue = 0;
  securities.sort((a, b) => {
    if (a.strikePrice < b.strikePrice) {
      return 1;
    }
    if (a.strikePrice > b.strikePrice) {
      return -1;
    }
    return 0;
  });
  for (const s of securities) {
    if (marketRate < s.strikePrice) {
      let sellamount = remainingQuantity;
      if (remainingQuantity > s.strikeQuantityRemaining) {
        sellamount = s.strikeQuantityRemaining;
        s.strikeQuantityRemaining = 0;
        remainingQuantity -= sellamount;
      }
      else {
        sellamount = remainingQuantity;
        remainingQuantity = 0;
        s.strikeQuantityRemaining -= sellamount;
      }
      totalSaleValue += sellamount * s.strikePrice;
    }
    else {
      totalSaleValue += remainingQuantity * marketRate;
      remainingQuantity = 0;
      break;
    }
  }
  // anything not covered by options goes at market rate
  totalSaleValue += remainingQuantity * marketRate
  return totalSaleValue;
}

/*
  PutCallFlag: Either "put" or "call"
  S: Stock Price
  X: Strike Price
  T: Time to expiration (in milliseconds)
  r: Risk-free rate
  v: Volatility
  This is the same one found in http://www.espenhaug.com/black_scholes.html
  but written with proper indentation and a === instead of == because it's
  faster, and it doesn't declare 5 useless variables (although if you really
  want to do it to have more elegant code I left a commented CND function in
  the end)
*/
export const blackScholes = (optionType: string, S: number, X: number, T_ms: number, r: number, v: number) => {
  const T = T_ms / ONE_YEAR;
  var d1 = (Math.log(S / X) + (r + v * v / 2) * T) / (v * Math.sqrt(T));
  var d2 = d1 - v * Math.sqrt(T);
  if (optionType === SECURITY_TYPES.OPTION_CALL) {
    return (S * CND(d1) - X * Math.exp(-r * T) * CND(d2));
  } else {
    return (X * Math.exp(-r * T) * CND(-d2) - S * CND(-d1));
  }
}

/* The cummulative Normal distribution function: */
export const CND = (x: number): number => {
  if (x < 0) {
    return (1 - CND(-x));
  } else {
    const k = 1 / (1 + .2316419 * x);
    return (1 - Math.exp(-x * x / 2) / Math.sqrt(2 * Math.PI) * k * (.31938153 + k * (-.356563782 + k * (1.781477937 + k * (-1.821255978 + k * 1.330274429)))));
  }
}
