import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { SimulationParameters, SimulationTick } from '../../domain';
import { SET_SIMUATION_PARAMETERS, SetSimulationParameters, BeginSimulation, BEGIN_SIMULATION, PauseSimulation, PAUSE_SIMULATION, ResetSimulation, RESET_SIMULATION, TickSimulation, TICK_SIMULATION, CompleteSimulation, COMPLETE_SIMULATION } from './types';
import { AppState } from '..';

export const setSimulationParameters = (parameters: SimulationParameters): SetSimulationParameters => ({
  type: SET_SIMUATION_PARAMETERS,
  payload: parameters
});

export const beginSimulation = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
    const state: AppState = getState();
    const intervalTimer = setInterval(() => {
      dispatch(tickSimulation());
    }, state.simulation.parameters.tickInterval);
    const action: BeginSimulation = { type: BEGIN_SIMULATION, payload: intervalTimer }
    dispatch(action);
  }
}

export const pauseSimulation = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
    const state: AppState = getState();
    clearInterval(state.simulation.intervalTimer);

    const action: PauseSimulation = { type: PAUSE_SIMULATION }
    dispatch(action);
  }
}

export const resetSimulation = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {

    const state: AppState = getState();

    clearInterval(state.simulation.intervalTimer);

    const action: ResetSimulation = { type: RESET_SIMULATION }
    dispatch(action)
  }
}

export const tickSimulation = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
    const state: AppState = getState();
    const { simulationTime, intervalTimer } = state.simulation;
    const { startTimestamp, duration, msPerMs, tickInterval } = state.simulation.parameters
    if (simulationTime >= (startTimestamp + duration)) {
      clearInterval(intervalTimer);
      const action: CompleteSimulation = { type: COMPLETE_SIMULATION }
      dispatch(action);
    }
    else {
      const elapsed = msPerMs * tickInterval;
      const newtime = simulationTime + elapsed;

      const tick: SimulationTick = { oldTimestamp: simulationTime, newTimestamp: newtime, elapsed: elapsed, parameters: state.simulation.parameters }
      const action: TickSimulation = { type: TICK_SIMULATION, payload: tick }
      dispatch(action);
    }
  }
}
