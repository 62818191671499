import React from 'react';
import echarts from 'echarts';
import { ConfidencePoint, ClientInvestmentReturn } from '../../domain';
import { getScale, applyScale } from '../../util';
import { ONE_YEAR, GRAPH_COLORS } from '../../const';
import { COMPOUND_TYPE } from '../../const/compound-type';
import { DisplayChart } from '../display-chart';


interface InvestmentReturnsChartProps {
  principal: number;
  simulationTime?: number;
  height: number;
  data: Array<ConfidencePoint>;
  currency: string;
  currencySymbol: string;
  rangeMin?: number;
  rangeMax?: number;
  actualReturns: Array<ClientInvestmentReturn>;
  showLegend?: boolean;
}

interface InvestmentReturnsChartState {
}


export class InvestmentReturnsChart extends React.Component<InvestmentReturnsChartProps, InvestmentReturnsChartState>  {
  baseOffset: number;

  static defaultProps = {
    height: 200,
    returnPeriod: ONE_YEAR,
    compoundType: COMPOUND_TYPE.CONTINUOUS_EXPONENTIAL,
    points: 10,
    currency: '',
    currencySymbol: '',
    actualReturns: []
  }
  constructor(props: InvestmentReturnsChartProps) {
    super(props);

    this.baseOffset = 0;

    // this.baseOffset = -props.data.reduce(function (min, val) {
    //   return Math.floor(Math.min(min, val.lower));
    // }, Infinity);
  }
  getSeries() {
    const xMin = this.props.data[0].timestamp;
    const xMax = this.props.data[this.props.data.length - 1].timestamp;

    return [{
      type: 'line',
      data: this.props.data.map((item: ConfidencePoint) => {
        return [new Date(item.timestamp) as unknown as number, item.lower + this.baseOffset];
      }),
      lineStyle: {
        opacity: 0,

      },
      stack: 'confidence-band',
      symbol: 'none'
    }, {
      type: 'line',
      data: this.props.data.map((item: ConfidencePoint) => {
        return [new Date(item.timestamp) as unknown as number, item.upper - item.lower];
      }),
      lineStyle: {
        opacity: 0
      },
      areaStyle: {
        color: '#ccc'
      },
      stack: 'confidence-band',
      symbol: 'none'
    }, {
      type: 'line',
      name: 'Projected Returns',
      data: this.props.data.map((item: ConfidencePoint) => {
        return [new Date(item.timestamp) as unknown as number, item.value + this.baseOffset];
      }),
      hoverAnimation: false,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: GRAPH_COLORS.PROJECTED_RETURNS
        }
      },
      showSymbol: false
    },
    {
      type: 'line',
      name: 'Actual Returns',
      data: this.props.actualReturns.map((item: ClientInvestmentReturn) => {
        return [new Date(item.timestamp) as unknown as number, item.fiatHolding + this.baseOffset];
      }),
      hoverAnimation: false,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: GRAPH_COLORS.ACTUAL_RETURNS
        }
      },
      showSymbol: false
    },
    {
      type: 'line',
      name: 'Actual Cost',
      data: this.props.actualReturns.map((item: ClientInvestmentReturn) => {
        return [new Date(item.timestamp) as unknown as number, item.powerCost + this.baseOffset];
      }),
      hoverAnimation: false,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: GRAPH_COLORS.ACTUAL_COST
        }
      },
      showSymbol: false
    },
    {
      type: 'line',
      name: 'Break Even',
      data: [
        [xMin, this.props.principal],
        [xMax, this.props.principal]
      ],
      hoverAnimation: false,
      symbolSize: 6,
      itemStyle: {
        normal: {
          color: GRAPH_COLORS.BREAK_EVEN
        }
      },
      showSymbol: false
    }]
  }
  getLegend() {
    if (this.props.showLegend === true) {
      return {
        show: true,
        orient: 'vertical',
        right: 30
      }
    }
    return {
      show: false
    }
  }
  getOptions(): echarts.EChartOption {

    const xPointer = (
      this.props.simulationTime !== undefined
        ? {
          show: true,
          status: 'show' as any,
          type: 'line' as any,
          value: this.props.simulationTime,
        }
        : undefined
    )

    const yScale = getScale(this.props.data.map(d => d.upper), true);


    const option = {
      tooltip: {
        show: false,
        formatter: function (params: any) {
          return 'test';
        } as any
      },
      legend: this.getLegend(),
      grid: {
        top: 10,
        left: 80,
        right: (this.props.showLegend ? 180 : 20)
      },
      xAxis: {
        type: 'time' as any,
        axisPointer: xPointer,
        axisLabel: {
          fontSize: 9.5
        }
      },
      yAxis: {
        type: 'value' as any,
        axisLabel: {
          formatter: (val: any) => {
            return `${this.props.currencySymbol}${applyScale(val - this.baseOffset, yScale, true)} ${this.props.currency}`;
          },
          fontSize: 9.5
        },
        min: this.props.rangeMin,
        max: this.props.rangeMax
      },

      series: this.getSeries(),

    };

    return option as any;

  }

  render() {
    const { height } = this.props
    return (
      <DisplayChart options={this.getOptions()} height={height} />
    )
  }
}
