import React from 'react';
import './App.css';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { NavigationBar } from './containers/navigation-bar';
import { SimulationBarWrapped } from './containers/simulation-bar';
import { SimulationParametersViewWrapped } from './containers/simulation-parameters-view';
import { ClientView } from './containers/client-view';
import { CLIENT_VIEW, SIMULATION_PARAMETERS, MINER_VIEW } from './const/routes';
import { MinerView } from './containers/miner-view';

export class App extends React.Component {
  render() {
    return (
      <div>
        <NavigationBar />

        <div className='container' style={{ paddingTop: 110, paddingBottom: 120, maxWidth: 900 }}>

          <Switch>
            <Route exact path="/">
              <Redirect to={CLIENT_VIEW.route} />
            </Route>
            <Route path={CLIENT_VIEW.route}>
              <ClientView />
            </Route>
            <Route path={MINER_VIEW.route}>
              <MinerView />
            </Route>
            <Route path={SIMULATION_PARAMETERS.route}>
              <SimulationParametersViewWrapped />
            </Route>
          </Switch>
        </div>
        <SimulationBarWrapped />
      </div>
    );
  }

}