import { SimulationState, SimulationActionTypes, SET_SIMUATION_PARAMETERS, BEGIN_SIMULATION, SIMULATION_MODE, PAUSE_SIMULATION, RESET_SIMULATION, TICK_SIMULATION, COMPLETE_SIMULATION } from "./types"

import { ONE_YEAR, ONE_DAY } from "../../const/time";
import { randTimeSeries } from "../../util";
import { DataPoint } from "../../domain";
import { HALVENING } from "../../const";
import { BTCUSD } from "../../faker";

const initialTime = Date.now();

const getYieldSeries = (minTime: number, maxTime: number, points: number) => {
  const arr: Array<DataPoint> = [];
  for (let i = 0; i < points; i++) {
    const ts = (i / (points - 1)) * (maxTime - minTime) + minTime;
    arr.push({
      timestamp: ts,
      value: (ts < HALVENING ? 12.5 : 6.25)
    })
  }

  return arr;
}

const initialState: SimulationState = {
  mode: SIMULATION_MODE.PENDING,
  simulationTime: initialTime,
  parameters: {
    msPerMs: 5e6,
    startTimestamp: initialTime,
    duration: ONE_YEAR + 60 * ONE_DAY,
    tickInterval: 100,
    btcPriceSeries: randTimeSeries(5000, 17000, initialTime, initialTime + ONE_YEAR, 7, BTCUSD),
    pwrPriceSeries: randTimeSeries(0.06, 0.09, initialTime, initialTime + ONE_YEAR, 7),
    miningDifficultySeries: randTimeSeries(11, 16, initialTime, initialTime + ONE_YEAR, 7),
    blockYieldSeries: getYieldSeries(initialTime, initialTime + ONE_YEAR, 21)
  }
}

export function simulationReducer(
  state = initialState,
  action: SimulationActionTypes
): SimulationState {
  switch (action.type) {
    case BEGIN_SIMULATION:
      return {
        ...state,
        mode: SIMULATION_MODE.RUNNING,
        intervalTimer: action.payload
      }
    case PAUSE_SIMULATION:
      return {
        ...state,
        mode: SIMULATION_MODE.PAUSED
      }
    case RESET_SIMULATION:
      return {
        ...state,
        mode: SIMULATION_MODE.PENDING,
        simulationTime: state.parameters.startTimestamp
      }
    case COMPLETE_SIMULATION:
      return {
        ...state,
        mode: SIMULATION_MODE.COMPLETE
      }
    case TICK_SIMULATION:
      if (state.mode === SIMULATION_MODE.RUNNING) {

        return {
          ...state,
          simulationTime: action.payload.newTimestamp
        }
      }
      else {
        throw new Error(`Can only tick simulation when mode = ${SIMULATION_MODE.RUNNING}`);
      }
    case SET_SIMUATION_PARAMETERS:
      return {
        ...state,
        parameters: action.payload
      }
    default:
      return state;
  }
}