import { Security, SECURITY_TYPES } from "../domain";
import { ulid } from "ulid";
import { rand, blackScholes } from "../util";

export const getSecurity = (
  type: string,
  asset: string,
  assetUnits: string,
  currency: string,
  todayPrice: number,
  strikePrice: number,
  quantity: number,
  termMs: number,
): Security => {
  switch (type) {
    case SECURITY_TYPES.FUTURE:
      // TODO Future
      return {
        uuid: ulid(),
        type: type,
        asset: asset,
        assetUnits: assetUnits,
        currency: currency,
        strikePrice: 0,
        strikeQuantity: quantity,
        strikeQuantityRemaining: quantity,
        cost: 0,
      }
    case SECURITY_TYPES.OPTION_CALL:
    case SECURITY_TYPES.OPTION_PUT:
      const riskFreeRate = rand(0.01, 0.1)
      const volality = rand(0.7, 0.8);
      const cost = blackScholes(type, todayPrice, strikePrice, termMs, riskFreeRate, volality)
      const security: Security = {
        uuid: ulid(),
        type: type,
        asset: asset,
        assetUnits: assetUnits,
        currency: currency,
        strikePrice: strikePrice,
        strikeQuantity: quantity,
        strikeQuantityRemaining: quantity,
        cost: cost * quantity,
      }
      return security;
    default:
      throw new Error('Unrecognised security type: ' + type);

  }
}

export const getSecurities = (
  type: string,
  asset: string,
  assetUnits: string,
  currency: string,
  todayPrice: number,
  strikePrice: number,
  quantity: number,
  termMs: number,
  clips: number = 8
): Array<Security> => {
  if (quantity < 0) {
    throw new Error('Quantity must be positive: ' + quantity)
  }
  let remainingQuantity = quantity;
  const sec = [];
  let i = 0;
  while (remainingQuantity > 0) {
    i++;
    let q = rand(quantity / (clips * 0.5), quantity / (clips * 2));
    if (i >= 100) {
      throw new Error('Help!');
    }
    else {

      if (q >= remainingQuantity) {
        q = remainingQuantity;
        remainingQuantity = 0;
      }
      else {
        remainingQuantity = remainingQuantity - q;
      }
    }
    const s = getSecurity(type, asset, assetUnits, currency, todayPrice, strikePrice, q, termMs);

    sec.push(s);
  }
  return sec;
}