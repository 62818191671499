import { Mine, InvestmentTranche, Term, ConfidencePoint } from "../domain";
import { randInt, getMiningReturns } from "../util";
import { ONE_DAY } from "../const";
import { TERM } from "../const/term";
import { projectPwrUsdKWH, projectMiningDifficulty, projectBlockYield } from ".";

const reducePrecision = (num: number) => {
  return parseFloat(num.toExponential(2))
}

export const fakeTranches = (count: number, mines: Array<Mine>): Array<InvestmentTranche> => {
  const tranches: Array<InvestmentTranche> = [];

  for (let i = 0; i < count; i++) {

    const mine = mines[randInt(0, mines.length)];

    const term = getTerm(i);
    const startDate = Date.now() + randInt(0, 60) * ONE_DAY;
    const endDate = startDate + term.approxMs;
    const expectedReturns: Array<ConfidencePoint> = [];
    const interval = term.approxMs / 100;
    let totalPowerCost = 0;
    let previousMiningReturns: ConfidencePoint = {
      timestamp: startDate,
      upper: 0,
      value: 0,
      lower: 0
    }
    expectedReturns.push(previousMiningReturns);
    for (let ts = startDate + interval; ts < endDate; ts += interval) {
      const projectedPwrUsd = projectPwrUsdKWH(ts);
      const projectedMiningDifficulty = projectMiningDifficulty(ts);
      const projectedBlockYield = projectBlockYield(ts);
      const miningReturnsUpper = getMiningReturns({
        from: ts - interval,
        to: ts,
        hashingPowerInTH: mine.hashingPower,
        pwrConsumptionInW: mine.powerConsumption,
        pwrCostPerKWH: projectedPwrUsd.upper,
        blockReward: projectedBlockYield.upper,
        miningDifficultyInT: projectedMiningDifficulty.upper
      });
      const miningReturnsMid = getMiningReturns({
        from: ts - interval,
        to: ts,
        hashingPowerInTH: mine.hashingPower,
        pwrConsumptionInW: mine.powerConsumption,
        pwrCostPerKWH: projectedPwrUsd.value,
        blockReward: projectedBlockYield.value,
        miningDifficultyInT: projectedMiningDifficulty.value
      });
      const miningReturnsLower = getMiningReturns({
        from: ts - interval,
        to: ts,
        hashingPowerInTH: mine.hashingPower,
        pwrConsumptionInW: mine.powerConsumption,
        pwrCostPerKWH: projectedPwrUsd.lower,
        blockReward: projectedBlockYield.lower,
        miningDifficultyInT: projectedMiningDifficulty.lower
      });
      previousMiningReturns = {
        timestamp: ts,
        upper: previousMiningReturns.upper + miningReturnsUpper.cryptoMined,
        value: previousMiningReturns.value + miningReturnsMid.cryptoMined,
        lower: previousMiningReturns.lower + miningReturnsLower.cryptoMined
      }
      expectedReturns.push(previousMiningReturns);
      totalPowerCost += miningReturnsMid.electricityCost;
    }

    const tranche: InvestmentTranche = {
      startDate: startDate,
      term: term,
      totalVolume: reducePrecision(totalPowerCost * (1 + mine.feePercent)),
      expectedReturns: expectedReturns,
      mine: mine,
      uuid: i.toString()
    }

    tranches.push(tranche)
  }

  return tranches;
}

const getTerm = (index: number): Term => {
  switch (index % 2) {
    case 0:
      return TERM.SIX_MONTH;
    case 1:
    default:
      return TERM.ONE_YEAR;
  }
}
