import { ConfidencePoint } from "../domain";
import { ONE_YEAR, HALVENING } from "../const";
import { rand } from "../util";

export const BTCUSD = 51200;
export const BTCUSD_RiskFreeRate = 0.1;
export const BTCUSD_Variance = 0.75;

const contain = (val: number) => (val < 0 ? 0 : val)

export const projectBtcUsd = (timestamp: number): ConfidencePoint => {
  const timespan = timestamp - Date.now();
  const years = timespan / ONE_YEAR;
  const value = Math.pow(1 + BTCUSD_RiskFreeRate, years);
  const upper = Math.pow(1 + BTCUSD_RiskFreeRate + BTCUSD_Variance, years);
  const lower = Math.pow(1 + BTCUSD_RiskFreeRate - BTCUSD_Variance, years);

  return {
    timestamp: timestamp,
    value: BTCUSD * contain(value),
    upper: BTCUSD * contain(upper),
    lower: BTCUSD * contain(lower)
  }
}

export const projectMiningDifficulty = (timestamp: number): ConfidencePoint => {
  const est = 120;
  return {
    timestamp: timestamp,
    value: est,
    upper: est * 1.001,
    lower: est * 0.999
  }
}

export const projectBlockYield = (timestamp: number): ConfidencePoint => {
  const est = 6.25;
  return {
    timestamp: timestamp,
    value: est,
    upper: est,
    lower: est
  }
}

export const projectPwrUsdKWH = (timestamp: number) => {
  const est = rand(0.03, 0.04)
  return {
    timestamp: timestamp,
    value: est,
    upper: est * 1.001,
    lower: est * 0.999
  }
}
