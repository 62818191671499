import React from 'react';

type ReturnType = number | string;

interface InputSelectProps {
  label?: string;
  debounce: number;
  value: any;
  options: Array<{ value: ReturnType, label: string }>;
  onChange: (value: ReturnType) => void;
}

interface InputSelectState {
  tempVal: ReturnType;
}

export class InputSelect extends React.Component<InputSelectProps, InputSelectState> {
  static defaultProps = {
    debounce: 200
  }
  timer?: any;
  constructor(props: InputSelectProps) {
    super(props);

    this.state = {
      tempVal: props.value
    }
  }
  propagate = () => {
    this.props.onChange(this.state.tempVal);
  }
  setPropagateTimer() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(this.propagate, this.props.debounce);
  }
  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ tempVal: event.target.value });
    this.setPropagateTimer();
  }
  renderLabel() {
    const { label } = this.props;
    if (label === undefined) {
      return null;
    }
    return (<label>{label}</label>)
  }
  render() {
    return (
      <div className='form-group'>
        {this.renderLabel()}
        <select className='form-control' value={this.props.value} onChange={this.handleChange}>
          {this.props.options.map(o => (
            <option key={o.value} value={o.value}>{o.label}</option>
          ))}
        </select>
      </div>
    )
  }
}