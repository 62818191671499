import React, { RefObject } from 'react';
import { DataPoint, PositionNum, PositionDateIndex } from '../../../domain';
import echarts, { ECharts } from 'echarts';




const toPositionIndex = (position: PositionNum, index: number): PositionDateIndex => ([new Date(position[0]), position[1], index])

interface DataGraphPoint {
  graphPosition: PositionNum,
  dataPosition: PositionNum,
  index: number
}

interface DraggableChartProps {
  data: Array<DataPoint>;
  rangeMin?: number;
  rangeMax?: number;
  onChange: (data: Array<DataPoint>) => void;
  simulationTime: number;
}

interface DraggableChartState {
}

const symbolSize = 8;

// var data = [[15, 0], [-50, 10], [-56.5, 20], [-46.5, 30], [-22.1, 40]];


export class DraggableChart extends React.Component<DraggableChartProps, DraggableChartState>  {
  chartRef: RefObject<HTMLDivElement>;
  chart?: ECharts;
  dataState: Array<DataGraphPoint>;
  constructor(props: DraggableChartProps) {
    super(props);

    this.chartRef = React.createRef();
    this.dataState = props.data.map((d: DataPoint, i: number): DataGraphPoint => ({
      graphPosition: [d.timestamp, d.value],
      dataPosition: [d.timestamp, d.value],
      index: i
    }))

    this.state = {

    }
  }
  shouldComponentUpdate(newProps: DraggableChartProps) {
    if (newProps.simulationTime !== this.props.simulationTime && this.chart !== undefined) {
      this.chart.setOption({
        xAxis: {
          axisPointer: {
            value: newProps.simulationTime,
          },
        }
      })
    }
    return false;
  }
  componentDidMount() {
    var dom: HTMLDivElement | null = this.chartRef.current;// document.getElementById(id) as HTMLDivElement;
    if (dom !== null) {


      var myChart = echarts.init(dom);
      this.chart = myChart;

      const option = {
        tooltip: {
          triggerOn: 'none',
          formatter: function (params: { data: PositionDateIndex }) {
            return params.data[1].toFixed(2);
          }
        },
        grid: {
          top: 10,
          left: 60
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          axisLine: {
            onZero: true,
            lineStyle: {
              color: '#888'
            }
          },
          splitLine: {
            show: false
          },
          axisPointer: {
            show: true,
            status: 'show',
            type: 'line',
            value: this.props.simulationTime,
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            onZero: false
          }
        },
        series: [{
          id: 'a',
          type: 'line',
          smooth: true,
          symbolSize: symbolSize,
          data: this.dataState.map(d => toPositionIndex(d.dataPosition, d.index))
        }]
      };
      setTimeout(this.renderDraggableArea, 0);

      if (option && typeof option === "object") {
        myChart.setOption(option as any, true);
      }

      window.addEventListener('resize', this.updatePosition);

      myChart.on('dataZoom', this.updatePosition);

      // Hack to redraw the the draggble areas because sometimes they get fkd up
      setInterval(this.updatePosition, 10000);
    }
  }
  createGraphicPoint = (point: DataGraphPoint) => {
    const self = this;
    if (this.chart !== undefined) {
      point.graphPosition = this.chart.convertToPixel('grid' as any, point.dataPosition) as PositionNum;
      return {
        type: 'circle',
        position: point.graphPosition,
        shape: {
          cx: 0,
          cy: 0,
          r: symbolSize
        },
        invisible: true,
        draggable: true,
        ondrag: function () {
          self.onPointDragging(point.index, this.position as PositionNum)
        },
        ondragend: this.handleDragEnd,
        onmousemove: () => this.showTooltip(point.index),
        onmouseout: () => this.hideTooltip(point.index),
        z: 200
      };
    }
  }
  renderDraggableArea = () => {
    const { chart } = this;
    if (chart !== undefined) {

      // Add shadow circles (which is not visible) to enable drag.
      chart.setOption({
        graphic: this.dataState.map(this.createGraphicPoint)
      });
    }
  }


  updatePosition = () => {
    const { chart } = this;
    if (chart !== undefined) {
      chart.setOption({
        graphic: this.dataState.map(this.createGraphicPoint)
      });
    }
  }
  showTooltip = (dataIndex: number) => {
    if (this.chart !== undefined) {
      this.chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: dataIndex
      });
    }
  }
  hideTooltip = (dataIndex: number) => {
    if (this.chart !== undefined) {
      this.chart.dispatchAction({
        type: 'hideTip'
      });
    }
  }
  onPointDragging = (dataIndex: number, pixel: PositionNum) => {
    if (this.chart !== undefined) {
      // fix the position in time
      const t = this.dataState[dataIndex].dataPosition[0];
      this.dataState[dataIndex].dataPosition = this.chart.convertFromPixel('grid' as any, pixel) as PositionNum;
      this.dataState[dataIndex].dataPosition[0] = t;

      // Update data
      this.chart.setOption({
        series: [{
          id: 'a',
          data: this.dataState.map(d => toPositionIndex(d.dataPosition, d.index)) as Array<number[]>
        }]
      });
    }
  };
  handleDragEnd = () => {
    this.props.onChange(this.dataState.map(d => ({ timestamp: d.dataPosition[0], value: d.dataPosition[1] })));
    setTimeout(this.updatePosition, 1)
  }
  render() {


    return (
      <div ref={this.chartRef} style={{ height: 200 }}>

      </div>
    )
  }
}

