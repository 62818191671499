import React, { RefObject } from 'react';
import echarts, { ECharts } from 'echarts';
import { PositionDateIndex, Security, PositionNum, SECURITY_TYPES } from '../../domain';
import { getScale, applyScale, logNormalDistribution } from '../../util';
import { ONE_YEAR, GRAPH_COLORS } from '../../const';
import { COMPOUND_TYPE } from '../../const/compound-type';
import { DisplayChart } from '../display-chart';



interface SecurityGraphProps {
  securities: Array<Security>;
  height: number;
  points: number;
  quantity: number;
  principal: number;
  asset: string;
  assetUnits?: string;
  currency: string;
  currencyUnits?: string;
  type: string;
  showLegend?: boolean;
  rangeMax: number;
}

export class SecurityGraph extends React.Component<SecurityGraphProps>  {
  chartRef: RefObject<HTMLDivElement>;
  chart?: ECharts;
  finalPrice: Array<PositionNum>;
  normalPrice: Array<PositionNum>;
  probabilityDistribution: Array<PositionNum>;
  series: any;

  static defaultProps = {
    height: 200,
    returnPeriod: ONE_YEAR,
    compoundType: COMPOUND_TYPE.CONTINUOUS_EXPONENTIAL,
    points: 100
  }
  constructor(props: SecurityGraphProps) {
    super(props);

    this.chartRef = React.createRef();

    this.finalPrice = [];
    this.normalPrice = [];
    this.probabilityDistribution = [];

    this.series = [];

  }
  getSeries(reCalcSeries: boolean) {
    if (reCalcSeries) {
      this.finalPrice = [];
      this.probabilityDistribution = [];
      this.normalPrice = [];
      const { securities } = this.props;
      let strikePriceMax = 0;
      for (const security of securities) {
        if (security.strikePrice > strikePriceMax) {
          strikePriceMax = security.strikePrice;
        }
      }
      const rangeMin = 0;
      const rangeMax = this.props.rangeMax;

      const c = this.props.points;
      let finalPrice = 0;

      for (let i = 0; i <= c; i++) {
        const ratio = (i / c);
        const normalPrice = ratio * (rangeMax - rangeMin) + rangeMin;
        let normalCost = normalPrice * this.props.quantity;
        this.normalPrice.push([normalPrice, normalCost]);
        let totalSellValue = 0;
        let totalCost = 0;
        let remainingQuantityToSell = this.props.quantity;
        for (const security of securities) {
          totalCost += security.cost;
          const priceStrike = security.strikePrice;
          finalPrice = (normalPrice < priceStrike ? priceStrike : normalPrice);
          totalSellValue += finalPrice * security.strikeQuantity;
          remainingQuantityToSell -= security.strikeQuantity;
        }
        const normalisedX = normalPrice / rangeMax
        this.probabilityDistribution.push([normalPrice, logNormalDistribution(normalisedX * 6, 1.13, 0.25)])
        this.finalPrice.push([normalPrice, remainingQuantityToSell * normalPrice + totalSellValue - totalCost]);
      }

      // Echarts bug, can't hide second y-axis
      const maxProbability = this.probabilityDistribution.reduce((prev: number, curr: number[]) => (curr[1] > prev ? curr[1] : prev), 0)
      const scaleFactor = 1.3 * rangeMax / maxProbability;

      this.series = [
        {
          type: 'line',
          name: 'Unheged Returns',
          data: this.normalPrice,
          itemStyle: {
            normal: {
              color: GRAPH_COLORS.UNHEDGED_RETURNS
            }
          },
          showSymbol: false
        },
        {
          type: 'line',
          name: 'Hedged Returns',
          data: this.finalPrice,
          itemStyle: {
            normal: {
              color: GRAPH_COLORS.HEDGED_RETURNS
            }
          },
          showSymbol: false
        },
        {
          type: 'line',
          name: 'Break Even',
          data: [
            [rangeMin, this.props.principal],
            [rangeMax, this.props.principal]
          ],
          hoverAnimation: false,
          symbolSize: 6,
          itemStyle: {
            normal: {
              color: GRAPH_COLORS.BREAK_EVEN
            }
          },
          showSymbol: false
        },
        {
          type: 'line',
          name: 'Probability',
          data: this.probabilityDistribution.map(p => ([p[0], p[1] * scaleFactor])),
          showSymbol: false,
          lineStyle: {
            opacity: 0,
            color: GRAPH_COLORS.PROBABILITY_DISTRIBUTION
          },
          itemStyle: {
            normal: {
              color: GRAPH_COLORS.PROBABILITY_DISTRIBUTION
            }
          },
          areaStyle: {
            color: GRAPH_COLORS.PROBABILITY_DISTRIBUTION
          },
          symbol: 'none'
        }];
    }
    return this.series;
  }
  getLegend() {
    if (this.props.showLegend === true) {
      return {
        show: true,
        orient: 'vertical',
        right: 30
      }
    }
    return {
      show: false
    }
  }
  getOptions(): echarts.EChartOption {
    return {
      tooltip: {
        triggerOn: 'none',
        formatter: function (params: { data: PositionDateIndex }) {
          return params.data[1].toFixed(2);
        } as any
      },
      grid: [{
        top: 10,
        left: 90,
        right: (this.props.showLegend ? 180 : 20)
      }],
      series: this.getSeries(true),
      xAxis: this.xAxis(),
      yAxis: this.yAxis(),
      legend: this.getLegend() as any
    };
  }
  xAxis(): any {
    const xScale = getScale(this.finalPrice.map(d => d[0]), true);
    const { currency, asset, assetUnits } = this.props;
    return {
      type: 'value',
      formatter: (val: any) => {
        return applyScale(val, xScale, true);
      },
      name: `${asset} Price (${currency}/${assetUnits !== undefined ? assetUnits : asset})`,
      nameLocation: 'center',
      nameGap: 30,
      max: this.normalPrice[this.normalPrice.length - 1][0]
    }
  }
  yAxis(): any {
    const { currency, type } = this.props;
    const yScale = getScale(this.finalPrice.map(d => d[1]), true);
    return [{
      id: 0,
      type: 'value',
      axisLabel: {
        formatter: (val: any) => {
          return applyScale(val, yScale, true);
        },
        fontSize: 9
      },
      name: (
        type === SECURITY_TYPES.OPTION_PUT
          ? `Total Revenue (${currency})`
          : `Total Cost (${currency})`
      ),
      nameLocation: 'center',
      nameGap: 75
    },
    {
      id: 1,
      type: 'value',
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
        lineStyle: {
          opacity: 0,
          color: '#FFF'
        },
        symbol: 'none',
      },
      axisTick: {
        show: false,
        lineStyle: {
          opacity: 0,
          color: '#FFF'
        }
      }

    }];
  }
  render() {
    const { height, asset, currency, type } = this.props;

    return (
      <div>
        <h6 className='text-center'>
          {type === SECURITY_TYPES.OPTION_PUT
            ? `Revenue (${currency}), based on Price of ${asset}`
            : `Liability (${currency}), based on Price of ${asset}`
          }
        </h6>
        <DisplayChart options={this.getOptions()} height={height} />
      </div>
    )
  }
}
