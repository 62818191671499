import { ONE_YEAR } from "../const";

export const getScale = (num: number | Array<number>, preferShorter: boolean = false): number => {
  if (typeof num === 'number') {
    const anum = Math.abs(num);
    if (anum < 1e-300) {
      return 0;
    }
    if (anum < 1e-2) {
      return -3;
    }

    if (anum > 1e7) {
      return 6
    }
    if (anum > 1e6 && preferShorter) {
      return 6;
    }
    if (anum > 1e5 && preferShorter) {
      return 3;
    }
    return 0;
  }
  else {
    const max: number = num.reduce((res: number, cur: number) => (res > Math.abs(cur) ? res : Math.abs(cur)), 0)
    return getScale(max, preferShorter)
  }
}


export const applyScale = (num: number, scale: number, preferShorter: boolean = false): string => {

  if (preferShorter) {
    const n = (num / Math.pow(10, scale));
    const d = Math.floor(n) !== n;
    return n.toFixed(d ? 2 : 0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + getSuffix(scale)

  }
  return (num / Math.pow(10, scale)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + getSuffix(scale);

}

const getSuffix = (depth: number) => {
  switch (depth) {
    case -3:
      return 'm';
    case 0:
      return '';
    case 3:
      return 'K';
    case 6:
      return 'M';
    case 9:
      return 'G';
    case 12:
      return 'T';
  }
}

export const formatVector = (vals: Array<number>, preferShorter: boolean = false): Array<string> => {

  const scale = getScale(vals, preferShorter);
  const retval: Array<string> = [];
  for (const v of vals) {
    retval.push(applyScale(v, scale));
  }

  return retval;
}

export const formatNum = (val: number, preferShorter: boolean = false): string => {
  const aval = Math.abs(val);
  if (aval < 1e-300) {
    return '0';
  }
  const scale = getScale(val, preferShorter);

  return applyScale(val, scale)
}

export const formatReturn = (val: number, timespan: number) => {
  val = val * 100;
  const apr = val * (ONE_YEAR / timespan);
  return `${val > 0 ? '+' : ''}${val.toPrecision(3)}%${timespan !== ONE_YEAR ? ` or ${apr.toPrecision(3)}% APR` : ''}`
}


export const formatDate = (ms: number, short: boolean = false) => {
  if (short) {
    const date = new Date(ms);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' : ''} ${day} /${month < 10 ? '0' : ''}${month}/${year} `
  }
  return (new Date(ms)).toDateString();
}

export const formatThousands = (val: number): string => {
  return val.toLocaleString();
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}