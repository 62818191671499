import { ClientInvestment, ReturnUpdateTick } from "../../domain"
import { TickSimulation, ResetSimulation } from "../simulation"

export const CREATE_INVESTMENT = 'CREATE_INVESTMENT'
export const SET_RETURN = 'SET_RETURN'
export const APPLY_RETURN_UPDATE = 'APPLY_RETURN_UPDATE'

export const SET_POTENTIAL_INVESTMENT = 'SET_POTENTIAL_INVESTMENT'

export interface CreateClientInvestment {
  type: typeof CREATE_INVESTMENT
  payload: ClientInvestment
}

export interface ApplyReturnUpdate {
  type: typeof APPLY_RETURN_UPDATE,
  payload: ReturnUpdateTick
}

export interface SetPotentialInvestment {
  type: typeof SET_POTENTIAL_INVESTMENT,
  payload?: ClientInvestment
}

export type ClientInvestmentTypes =
  CreateClientInvestment |
  ApplyReturnUpdate |
  TickSimulation |
  SetPotentialInvestment |
  ResetSimulation

export interface ClientInvestmentState {
  investments: Array<ClientInvestment>;
  potentialInvestment?: ClientInvestment;
}