import { SimulationParameters, SimulationTick } from "../../domain"

export const SET_SIMUATION_PARAMETERS = 'SET_SIMUATION_PARAMETERS'

export const BEGIN_SIMULATION = 'BEGIN_SIMULATION'
export const PAUSE_SIMULATION = 'PAUSE_SIMULATION'
export const RESET_SIMULATION = 'RESET_SIMULATION'
export const COMPLETE_SIMULATION = 'COMPLETE_SIMULATION'

export const TICK_SIMULATION = 'TICK_SIMULATION'

export const SIMULATION_MODE = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  COMPLETE: 'COMPLETE'
}

export interface SetSimulationParameters {
  type: typeof SET_SIMUATION_PARAMETERS;
  payload: SimulationParameters;
}

export interface BeginSimulation {
  type: typeof BEGIN_SIMULATION;
  payload: NodeJS.Timeout;
}

export interface PauseSimulation {
  type: typeof PAUSE_SIMULATION
}

export interface ResetSimulation {
  type: typeof RESET_SIMULATION
}

export interface CompleteSimulation {
  type: typeof COMPLETE_SIMULATION
}

export interface TickSimulation {
  type: typeof TICK_SIMULATION,
  payload: SimulationTick;
}

export type SimulationActionTypes =
  SetSimulationParameters |
  BeginSimulation |
  PauseSimulation |
  ResetSimulation |
  CompleteSimulation |
  TickSimulation

export interface SimulationState {
  mode: string;
  simulationTime: number;
  intervalTimer?: any;
  parameters: SimulationParameters;
}