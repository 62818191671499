import React from 'react';

import { connect } from 'react-redux'
import { AppState } from '../../../../../store';
import { InvestmentTrancheState } from '../../../../../domain';
import { Tranche } from './tranche';

interface TrancheListProps {
  availableTranches: Array<InvestmentTrancheState>;
  simulationTime: number;
}

interface TrancheListState {

}

export class TrancheList extends React.Component<TrancheListProps, TrancheListState> {


  render() {
    return (
      <div className='investment-list'>
        <h3 className='mb-3'>Investment Tranches</h3>
        <div >
          {this.props.availableTranches.map(t => (
            <Tranche
              key={t.parameters.uuid}
              tranche={t}
              simulationTime={this.props.simulationTime}
            />
          ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    availableTranches: state.mines.availableTranches,
    simulationTime: state.simulation.simulationTime
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
  }
}

export const TrancheListWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrancheList)
