import React from 'react';
import { QuantityCurrency } from '../../components/quantity-currency';
import { TERMS } from '../../const';
import { InputNumber } from '../../components/form/input-number';

export class MinerView extends React.Component {

  render() {
    return (
      <div className='row'>
        <div className='col-12'>
          <h2>Offer New Tranche</h2>
        </div>
        <div className='col-6'>
          <div className='form-group'>
            <label>Desired Fiat Amount</label>
            <QuantityCurrency
              currencies={['USD', 'EUR']}
              onChange={() => { }}
              value={{ currency: 'USD', quantity: 1e7 }}
            />
          </div>
        </div>
        <div className='col-6'>
          <div className='form-group'>
            <label>Committed Hash Rate (TH/s)</label>
            <InputNumber value={5e4} onChange={() => { }} />
          </div>
        </div>
        <div className='col-6'>
          <div className='form-group'>
            <label>Term</label>
            <select className='form-control'>
              {TERMS.map(t => (
                <option key={t.code}>{t.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-6'>
          <label>Start Date</label>
          <input className='form-control' value='Today' />
        </div>
        <div className='col-12 text-right '>
          <button className='btn btn-primary mr-4'>
            Preview
          </button>
          <button className='btn btn-secondary'>
            Cancel
          </button>
        </div>


      </div>
    )
  }
}

