import React from 'react';
import { InputSlider } from '../form/input-slider';
import { RiskParameters } from '../../domain';
import { HEDGE_STRATEGIES } from '../../const/hedge-strategies';
import LimitDownside from './limit_downside.png';
import CoverCost from './cover_cost.png';
import AgressUpside from './agress_upside.png';
import classNames from 'classnames';
import './style.css';
import { IconCog } from '../icons';

interface InputRiskSliderProps {
  value: RiskParameters;
  onChange: (value: RiskParameters) => void;
  title?: string;
  label?: string;
}

interface InputRiskSliderState {
  advancedMode: boolean;
}

interface Strategy {
  label: string;
  img?: string;
  code: string;
}
export const RiskSliderDefaults: RiskParameters = {
  strategy: HEDGE_STRATEGIES.LIMIT_DOWNSIDE,
  earlyLiquidation: 0.1,
  strikeFromMarketPercent: 0.1,
  hedgeDownsidePercent: 0.3,
  hedgeUpsidePercent: 1,
  liquidationPercent: 0
}
const strategies: Array<Strategy> = [
  {
    label: 'Limit Downside',
    img: LimitDownside,
    code: HEDGE_STRATEGIES.LIMIT_DOWNSIDE
  },
  {
    label: 'Cover Cost',
    img: CoverCost,
    code: HEDGE_STRATEGIES.COVER_COST
  },
  {
    label: 'Maximise Upside',
    img: AgressUpside,
    code: HEDGE_STRATEGIES.AGRESS_UPSIDE
  }
]

export class InputRiskSlider extends React.PureComponent<InputRiskSliderProps, InputRiskSliderState> {
  static defaultProps = {
    label: 'Risk Tolerance'
  }
  constructor(props: InputRiskSliderProps) {
    super(props);

    this.state = {
      advancedMode: false
    }
  }
  handleStrikeFromMarketChange = (value: number) => {
    this.props.onChange({ ...this.props.value, strikeFromMarketPercent: value });
  }
  handleHedgeUpsidePercentChange = (value: number) => {
    this.props.onChange({ ...this.props.value, hedgeUpsidePercent: value });
  }
  handleHedgeDownsidePercentChange = (value: number) => {
    this.props.onChange({ ...this.props.value, hedgeDownsidePercent: value });
  }
  handleLiquidationPercentChange = (value: number) => {
    this.props.onChange({ ...this.props.value, liquidationPercent: value });
  }
  handleEarlyLiquidationThresholdChange = (value: number) => {
    this.props.onChange({ ...this.props.value, earlyLiquidation: value });
  }
  handleRiskToleranceChange = (value: number) => {
    console.log(value);
    this.props.onChange({
      ...this.props.value,
      hedgeDownsidePercent: value,
    });
  }
  handleAdvancedClicked = () => {
    if (this.state.advancedMode) {
      this.props.onChange({
        ...this.props.value,
        strategy: HEDGE_STRATEGIES.LIMIT_DOWNSIDE,
      })
    }
    this.setState({ advancedMode: !this.state.advancedMode });
  }
  handleStrategyClick(code: string, event: React.MouseEvent) {
    this.props.onChange({
      ...this.props.value,
      strategy: code
    });
  }
  handleResetDefaultsClick = () => {
    this.props.onChange(RiskSliderDefaults);
  }
  renderLabel() {
    const { label } = this.props;
    return (
      <div>
        {label !== undefined ? <label>{label}</label> : null}
        <span
          className='glyphicon glyphicon-cog'
          onClick={this.handleAdvancedClicked}
          style={{ marginLeft: 10, cursor: 'pointer' }}
        ><IconCog style={{ position: 'relative', top: 2 }} /> {this.state.advancedMode ? 'Simple Mode' : 'Advanced Mode'}</span>
      </div>
    )
  }
  renderStrategySelection() {
    return (
      <div className='row mb-3'>
        {
          strategies.map(s => (
            <div key={s.code} className={classNames('col-sm-4', 'text-center', 'hedge-strategy', { 'active': (this.props.value.strategy === s.code) })} onClick={this.handleStrategyClick.bind(this, s.code)}>
              <img alt={s.label} src={s.img} style={{ width: '100%', maxWidth: 130 }} />
              <label style={{ width: 130 }}>{s.label}</label>
            </div>
          ))
        }
      </div>
    )
  }
  renderSliders() {
    const steps = 20;
    const min = -1 / steps;
    const marks =
    {
      0.2: {
        style: '',
        label: 'Conservative'
      },
      0.5: {
        style: '',
        label: 'Moderate'
      },
      0.8: {
        style: '',
        label: 'Agressive'
      }
    }

    if (this.state.advancedMode === true) {
      const dis = {
        strikeFromMarketPercent: false,
        hedgeDownsidePercent: false,
        hedgeUpsidePercent: false,
        liquidationPercent: false,
        earlyLiquidation: false,
        comingSoon: false
      }

      switch (this.props.value.strategy) {

        case HEDGE_STRATEGIES.LIMIT_DOWNSIDE:
          dis.strikeFromMarketPercent = true;
          dis.hedgeDownsidePercent = true;
          dis.earlyLiquidation = true;
          dis.liquidationPercent = true;
          break;
        case HEDGE_STRATEGIES.COVER_COST:
          dis.comingSoon = true;
          // dis.strikeFromMarketPercent = true;
          // dis.hedgeDownsidePercent = true;
          // dis.hedgeUpsidePercent = true;
          // dis.earlyLiquidation = true;
          // dis.liquidationPercent = true;
          break;
        case HEDGE_STRATEGIES.AGRESS_UPSIDE:
          dis.comingSoon = true;
          // dis.strikeFromMarketPercent = true;
          // dis.hedgeDownsidePercent = true;
          // dis.hedgeUpsidePercent = true;
          // dis.earlyLiquidation = true;
          // dis.liquidationPercent = true;
          break;
        default:
          dis.comingSoon = true;
          break
      }
      return (
        <div className='form-group mt-3 mb-5' style={{ maxWidth: 700, margin: 'auto' }}>
          {this.renderStrategySelection()}
          <div className='text-right'>
            <button type='button' className='btn btn-secondary btn-sm pull-right' onClick={this.handleResetDefaultsClick} >Reset Defaults</button>
          </div>
          {dis.comingSoon ? <h4 className='text-center'>Coming Soon...</h4> : null}
          {dis.strikeFromMarketPercent
            ? <InputSlider
              marks={marks} rescale={steps} availableMin={0}
              label='Strike Price Percent from Market'
              description='Options purchased must be X percent from market price'
              min={min} max={1} value={this.props.value.strikeFromMarketPercent} onChange={this.handleStrikeFromMarketChange} />
            : null}
          {dis.hedgeDownsidePercent
            ? <InputSlider marks={marks} rescale={steps} availableMin={0}
              label='Unhedged Downside Percent'
              description='Do not hedge X percent of mined crypto (projected)'
              min={min} max={1} value={this.props.value.hedgeDownsidePercent} onChange={this.handleHedgeDownsidePercentChange} />
            : null}
          {dis.hedgeUpsidePercent
            ? <InputSlider marks={marks} rescale={steps} availableMin={0}
              label='Hedge Upside Percent'
              min={min} max={1} value={this.props.value.hedgeUpsidePercent} onChange={this.handleHedgeUpsidePercentChange} />
            : null}
          {dis.liquidationPercent
            ? <InputSlider marks={marks} rescale={steps} availableMin={0}
              label='Max Non-Liquidation Percent'
              description='At most X percent of mined crypto will be held as crypto'
              min={min} max={1} value={this.props.value.liquidationPercent} onChange={this.handleLiquidationPercentChange} />
            : null}
          {dis.earlyLiquidation
            ? <InputSlider marks={marks} rescale={steps} availableMin={0}
              label='Early Liquidation Threshold'
              description='Market price must be more than X percent above strike to liquidate'
              min={min} max={1} value={this.props.value.earlyLiquidation} onChange={this.handleEarlyLiquidationThresholdChange} />
            : null}
        </div>
      )
    }

    return (
      <div>
        <InputSlider

          marks={marks} rescale={steps} availableMin={0}
          min={min} max={1} value={this.props.value.hedgeDownsidePercent} onChange={this.handleRiskToleranceChange} />

        {(this.props.value.hedgeDownsidePercent <= 0.001)
          ? (
            <label><br /><br />Some level of risk is associated with every investment</label>
          )
          : null
        }
      </div>
    )
  }
  render() {
    return (
      <div className='form-group mt-2 '>


        {this.renderLabel()}
        {this.renderSliders()}
      </div>
    )
  }
}