import { Mine } from "../domain";
import { fakeName } from "./names";
import { ulid } from "ulid";
import { randInt } from "../util";

export const fakeMines = (count: number): Array<Mine> => {
  const m: Array<Mine> = [];
  for (let i = 0; i < count; i++) {
    const TH = randInt(10000, 1000000);

    m.push({
      title: fakeName(),
      uuid: ulid(),
      hashingPower: TH,
      powerConsumption: TH * 50,
      feeFixed: TH * 2,
      feePercent: 0.01
    })
  }
  return m;
}